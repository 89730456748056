import React, { memo, useCallback, useEffect } from 'react';
import { Dropdown, Tooltip } from 'antd';
import { LayoutConfig } from '@antv/g6';
import { LayoutWrapper } from './style';
import { ISettingsProps, LayoutType } from './types';

import { createCombos, graphRender } from 'components/layouts/components/visualisation/helpers/utils';
import { useGraph } from 'components/layouts/components/visualisation/wrapper';
import { formattedData } from 'components/layouts/components/visualisation/helpers/format-node';
import { initData } from 'components/layouts/components/visualisation/container/initial/nodes';
import { layoutConfig } from 'components/layouts/components/visualisation/helpers/constants';
import { useProject } from 'context/project-context';
import { useVisualizationHistory } from 'context/visualization-history';
import { useGetData } from 'api/visualisation/use-get-data';
import { useGetAllVisualizationById } from 'api/visualization-history/use-get-visualization-by-id';
import { UserProjectRole } from 'api/types';
import { VisualizationHistory } from '../visualisation-history';
import { ACTIONS } from 'context/visualization-history/reducer';
import { ProcessAndRenderArgs } from 'pages/project-visualisation/type';
import { ReactComponent as ComboSvg } from './icons/visualisation-combo-action.svg';
import { ReactComponent as RadialSvg } from './icons/visualisation-radial-action.svg';
import { ReactComponent as FazziMatchSvg } from './icons/visualisation-fuzzy-match-action.svg';
import { ReactComponent as ResetSvg } from './icons/visualisation-reset-action.svg';

import { VisualisatoinTools } from './constat';
import { radialMenuItems } from './items';
import { ShareVisualization } from './components/share-visualization';
import { SaveVisualization } from './components/save-visualization';
import { useGetExternalSharedData } from 'api/visualisation/use-get-external-shared-data';

const { COMBO, RADIAL, FAZZY_MATCH, RESET, SAVE_AS, SHARE } = VisualisatoinTools;

export const Settings: React.FC<ISettingsProps> = memo(({ isDocument, isProjectPublic = false, graph, isShared }) => {
  const { userHasPermission, visualizationId, handleAction, reset } = useVisualizationHistory();

  const { startFuzzyModal, setGraphInfo } = useGraph() || {};

  const { projectInfo } = useProject();

  const externalData = useGetExternalSharedData(!!isShared && reset);
  const internalData = useGetData({ enabled: reset && !isShared });

  const { nodes, edges, count, relationsCounts, savedGraph } = isShared ? externalData : internalData;

  const { data: savedData } = useGetAllVisualizationById(visualizationId, {
    enabled: !!visualizationId,
    onSuccess: (data) => {
      const { savedGraph } = data?.data;
      handleAction({
        type: ACTIONS.SET_SHARED_DATA,
        payload: savedGraph?.shared,
      });
    },
  });

  const onHandleReset = useCallback(() => {
    handleAction({
      type: ACTIONS.RESET_VISUALIZATION,
      payload: { isShared },
    });
  }, [handleAction, isShared]);

  const setLayout = useCallback(
    (layout: LayoutType) => {
      if (graph) {
        graph.destroyLayout();

        const params = { type: layout };

        const [x, y] = [window.innerWidth / 2, window.innerHeight / 2 + 150];

        graph.updateLayout({ ...params, ...(layoutConfig[layout] as LayoutConfig) }, 'center', { x, y }, true);

        setTimeout(() => {
          graph.fitCenter();
          graph.fitView([x, y], { onlyOutOfViewPort: true, direction: 'both', ratioRule: 'max' }, true);
        }, 1500);
      }
    },
    [graph]
  );

  const processAndRender = useCallback(
    async ({ nodeList, edgeList, relationsCounts, savedGraph, nodeCountValue }: ProcessAndRenderArgs) => {
      const data = formattedData(nodeList, edgeList, relationsCounts, savedGraph);
      setGraphInfo({
        nodeCountAPI: count,
        nodeCount: nodeCountValue,
      });
      if (data) {
        initData(graph, data);
        graphRender(graph, !!visualizationId);
      }
    },
    [count, graph, setGraphInfo, visualizationId]
  );

  useEffect(() => {
    if (savedData?.data?.count) {
      const { nodes, edges, relationsCounts } = savedData.data;
      processAndRender({
        nodeList: nodes,
        edgeList: edges,
        relationsCounts,
        savedGraph: savedData.savedGraph,
        nodeCountValue: nodes?.length,
      });
    } else if (savedGraph && nodes && edges) {
      processAndRender({
        nodeList: nodes,
        edgeList: edges,
        relationsCounts,
        savedGraph: savedGraph,
        nodeCountValue: nodes?.length,
      });
    } else if (reset) {
      processAndRender({
        nodeList: nodes ?? [],
        edgeList: edges ?? [],
        relationsCounts: relationsCounts ?? {},
        savedGraph: undefined,
        nodeCountValue: nodes?.length ?? 0,
      });
    }
  }, [
    savedData,
    graph,
    reset,
    nodes,
    edges,
    relationsCounts,
    visualizationId,
    setGraphInfo,
    count,
    processAndRender,
    isShared,
    savedGraph,
  ]);

  return (
    <>
      {(isProjectPublic || userHasPermission()) && <VisualizationHistory />}
      <LayoutWrapper vertical gap={16} isDocument={isDocument}>
        <Tooltip title={RESET}>
          <ResetSvg onClick={onHandleReset} />
        </Tooltip>
        <Dropdown overlay={radialMenuItems({ setLayout })} placement="bottomLeft" trigger={['click']}>
          <Tooltip title={RADIAL} placement="left">
            <RadialSvg />
          </Tooltip>
        </Dropdown>
        <Tooltip title={COMBO} placement="left">
          <ComboSvg onClick={() => createCombos(graph)} />
        </Tooltip>
        {projectInfo?.role === UserProjectRole.Owner && (
          <Tooltip title={FAZZY_MATCH} placement="left">
            <FazziMatchSvg onClick={() => startFuzzyModal({ isOpened: true })} />
          </Tooltip>
        )}
        {userHasPermission() && (
          <>
            <Tooltip title={SAVE_AS}>
              <SaveVisualization />
            </Tooltip>
            {visualizationId && (
              <Tooltip title={SHARE}>
                <ShareVisualization />
              </Tooltip>
            )}
          </>
        )}
      </LayoutWrapper>
    </>
  );
});
