import React from 'react';
import dayjs from 'dayjs';
import { Flex, Form, Tooltip } from 'antd';
import { Text } from 'components/typography';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';
import { dateFormat, VALIDATE_MESSAGES } from 'helpers/constants';
import { Datepicker } from 'components/datepicker';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export const DateTimeType: React.FC<AnyObject> = ({ id, question, hover, required, validation }) => {
  const label = (
    <Flex gap={16} align="center">
      <Text>{question}</Text>
      {hover && (
        <Tooltip title={hover}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Flex>
  );

  const disabledDate = (current: dayjs.Dayjs | null) => {
    if (!current) return true;

    const from = validation?.from ? dayjs(validation.from) : null;
    const to = validation?.to ? dayjs(validation.to) : null;

    if (from && to) {
      return !current.isBetween(from, to, 'day', '[]');
    }

    return false;
  };

  return (
    <Form.Item label={label} name={id} rules={[{ required, message: VALIDATE_MESSAGES.required }]}>
      <Datepicker
        format={dateFormat}
        style={{ width: '100%' }}
        showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm') }}
        disabledDate={disabledDate}
      />
    </Form.Item>
  );
};
