import React from 'react';
import { Flex, Form, InputNumber, Tooltip } from 'antd';
import { Text } from 'components/typography';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';

export const NumericType: React.FC<AnyObject> = ({ id, question, hover, placeholder, validation, required }) => {
  const label = (
    <Flex gap={16} align="center">
      <Text>{question}</Text>
      {hover && (
        <Tooltip title={hover}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Flex>
  );

  const rules = [
    {
      required,
      message: 'This field is required.',
    },
  ];

  return (
    <Form.Item label={label} name={id} rules={rules}>
      <InputNumber
        min={validation?.min}
        max={validation?.max}
        style={{ width: '100%' }}
        step={1}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};
