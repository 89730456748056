import React from 'react';
import { Flex, Form, Input, Tooltip } from 'antd';
import { Text } from 'components/typography';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';

export const TextType: React.FC<AnyObject> = ({
  id,
  question,
  hover,
  required,
  placeholder = 'Please write question',
  validation,
}) => {
  const label = (
    <Flex gap={16}>
      <Text>{question}</Text>
      {hover && (
        <Tooltip title={hover}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Flex>
  );

  const rules = [
    {
      required,
      message: 'This field is required.',
    },
    ...(validation?.email
      ? [
          {
            type: 'email' as const,
            message: 'Please enter a valid email address.',
          },
        ]
      : []),
    ...(validation?.min || validation?.max
      ? [
          {
            min: validation?.min || 1,
            max: validation?.max,
            message: `Field must be between ${validation?.min} and ${validation?.max} characters.`,
          },
        ]
      : []),
  ];

  return (
    <Form.Item label={label} name={id} rules={rules}>
      <Input placeholder={placeholder} />
    </Form.Item>
  );
};
