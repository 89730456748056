import { useMutation } from '@tanstack/react-query';
import client from 'api/client';
import { RequestTypes } from 'api/types';
import { URL_APP_FORMS_BY_PROJECT_ID } from './constant';
import { useParams } from 'react-router-dom';

/**
 * @namespace useCreateAppForms
 * @description Custom hook for creating app forms
 *
 * @returns {UseMutationResult} A mutation object for managing the form creation process.
 *
 * @example
 * const { mutate } = useCreateAppForms();
 * mutate(formData);
 */

export const useCreateAppForms = () => {
  const { id } = useParams();

  return useMutation({
    mutationFn: (formData: FormData) =>
      client[RequestTypes.Post](URL_APP_FORMS_BY_PROJECT_ID.replace(':project_id', id || ''), formData),
  });
};
