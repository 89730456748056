import React, { useCallback, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { Text } from 'components/typography';
import { NodeTypes } from './node-types';
import { NodeTypeProperties } from './node-type-properties';
import { InfoCircleOutlined } from '@ant-design/icons';
import { LeftSection } from '../style';
import { WarningModal } from 'components/modal/warning-modal';
import { Info } from './info';

export const LeftSectionForm: React.FC = () => {
  const [open, setIsOpen] = useState(false);

  const toggleModal = useCallback(() => setIsOpen((prev) => !prev), []);

  return (
    <>
      <LeftSection>
        <Flex align="center" gap={16}>
          <Text style={{ fontSize: 22 }}>Form Application</Text>
          <Tooltip title="Info Application Form">
            <InfoCircleOutlined onClick={toggleModal} />
          </Tooltip>
        </Flex>
        <NodeTypes />
        <NodeTypeProperties />
      </LeftSection>
      <WarningModal isOpen={open} onClose={toggleModal} text="About Application Form" title="Info">
        <Info />
      </WarningModal>
    </>
  );
};
