import { Col, Flex, List, Row, Spin, Tooltip, Typography } from 'antd';
import { RightSectionAllDataProps, UserProjectRole } from 'api/types';
import { Checkbox } from 'components/checkbox';
import { Text } from 'components/typography';
import { useProject } from 'context/project-context';
import { useIsPublicPage } from 'hooks/use-is-public-page';
import { COLORS, initPageData, ROLES_PROJECT } from 'helpers/constants';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ConnectionOneDirection } from 'components/layouts/components/data-sheet/utils';
import { RejectModal } from 'components/modal/reject-node-modal';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { NodePagination } from 'components/pagination';
import { useGetRejectApprowedConnection } from 'api/edges/use-edge-reject';
import { useDataSheetWrapper } from 'components/layouts/components/data-sheet/wrapper';
import { IDataConnection, IFilterDataConnection } from 'types/edges';
import { Draft, NodeButton, StyledListItem, TypeInfo, Wrapper } from './style';
import { DeleteAllDataModal } from 'components/modal/delete-all-data-modal';
import { ExpandableInput, SearchText } from 'components/input/expandable-input';
import { useViewDatasheetEdge } from 'context/datasheet-edge-view-vontext';
import { useGetAllConnection } from 'api/edges/use-get-all-connection';
import { Sort } from 'components/dropdown';
import { ALL_DATA_SORT_BY } from 'components/dropdown/constants';
import { useSort } from 'context/sort-context';

const { Paragraph } = Typography;

export const ConnectionListdata = () => {
  const { connectionLists: edge_type_ids } = useDataSheetWrapper();
  const { dispatch } = useViewDatasheetEdge();
  const { projectInfo } = useProject();
  const isPublicPage = useIsPublicPage();

  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  const [modalAction, setModalAction] = useState<'reject' | 'approve'>('reject');
  const [checkedItems, setCheckedItems] = useState<RightSectionAllDataProps[]>([]);
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [filterValue, setFilterValue] = useState<IFilterDataConnection>({
    page: 1,
    size: 10,
    search: undefined,
    is_draft: false,
  });

  const checkedDraft = checkedItems.some((item: { status: string }) => item.status === 'draft');
  const checkedId = checkedItems.map((item: { id: string }) => item.id);

  const status = modalAction === 'reject' ? 'rejected' : 'approved';
  const permissions = localStorage.getItem('permissions');
  const GetPermissions = permissions ? JSON.parse(permissions) : null;
  const permissionsRole = GetPermissions?.data.role;

  const onHandleToggleDeleteModal = useCallback(() => setIsOpenRejectModal((prev) => !prev), []);

  const { mutate: updateReject } = useGetRejectApprowedConnection();
  const { state: sortState } = useSort();

  const onFinish = useCallback(() => {
    updateReject(
      {
        ids: checkedId,
        status: status,
      },
      {
        onSuccess: () => {
          setCheckedItems([]);
        },
      }
    );

    setIsOpenRejectModal(false);
  }, [checkedId, status, updateReject]);

  const updateRejectStatus = (action: 'reject' | 'approve') => {
    setModalAction(action);
    setIsOpenRejectModal(true);
  };

  const {
    rowsData = [],
    count,
    isInitialLoading,
  } = useGetAllConnection({ ...filterValue, edge_type_ids, is_draft: filterValue.is_draft }, {});

  const handleItemClick = useCallback(
    (item: IDataConnection) => {
      const updatedCheckedItems = checkedItems.some((checkedItem) => checkedItem.id === item.id)
        ? checkedItems.filter((checkedItem) => checkedItem.id !== item.id)
        : [...checkedItems, { id: item.id, checked: false, status: item.status }];

      setIsAllCheck(updatedCheckedItems.length === rowsData.length);
      setCheckedItems(updatedCheckedItems);
    },
    [checkedItems, rowsData]
  );

  const setSearchText = useCallback(
    ({ text, type, is_draft }: SearchText) => {
      setFilterValue((prevValue) => ({
        ...prevValue,
        search: text.trim() === '' ? undefined : text,
        type: undefined,
        is_drafts: undefined,
        is_draft: is_draft ?? prevValue.is_draft,
      }));
    },
    [setFilterValue]
  );

  useEffect(() => {
    if (isAllCheck) {
      setCheckedItems(
        rowsData.map((item: RightSectionAllDataProps) => ({ id: item.id, checked: true, status: item.status }))
      );
    }
  }, [isAllCheck, rowsData]);
  useEffect(() => {
    const [orderName, order] = sortState?.split(' ') || [];

    if (orderName && order) {
      setFilterValue((prev) => ({
        ...prev,
        sortField: orderName,
        sortOrder: order,
      }));
    }
  }, [setFilterValue, sortState]);

  return (
    <Wrapper gap={20} vertical>
      <div>
        {projectInfo?.role !== UserProjectRole.Viewer && !isPublicPage && (
          <Flex align="start" gap={20} style={{ paddingLeft: 24 }} wrap justify="space-between">
            <Flex align="start" gap={20}>
              <Flex vertical gap={34}>
                <Sort prefix="Sort By:" sortItems={ALL_DATA_SORT_BY} fullWidth />
                <Checkbox
                  id="allCheck"
                  className="all-data-checkbox"
                  style={{ marginRight: '16px' }}
                  checked={isAllCheck}
                  onChange={() => {
                    if (!isAllCheck) {
                      setCheckedItems(
                        rowsData.map((item: RightSectionAllDataProps) => ({
                          id: item.id,
                          checked: true,
                          status: item.status,
                        }))
                      );
                    } else {
                      setCheckedItems([]);
                    }
                    setIsAllCheck((prev) => !prev);
                  }}
                >
                  <Text style={{ fontSize: 20 }} color="#808080">
                    All Connections
                  </Text>
                </Checkbox>
              </Flex>
              <Flex style={{ maxWidth: 400 }}>
                <ExpandableInput setSearchText={setSearchText} serachType={'connection'} />
              </Flex>
            </Flex>
            <Flex>
              {checkedDraft && permissionsRole === ROLES_PROJECT.OWNER ? (
                <Flex gap={16}>
                  <NodeButton type="primary" onClick={() => updateRejectStatus('approve')}>
                    <CheckOutlined />
                    Approve
                  </NodeButton>
                  <NodeButton onClick={() => updateRejectStatus('reject')}>
                    <CloseOutlined />
                    Reject
                  </NodeButton>
                </Flex>
              ) : null}
              <div>
                <DeleteAllDataModal
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  onSubmit={() => {
                    setIsAllCheck(false);
                  }}
                  checkText={'connection'}
                />
              </div>
            </Flex>
          </Flex>
        )}
      </div>
      <Spin spinning={isInitialLoading}>
        <List
          style={{ overflow: 'auto', height: `calc(91vh - ${300}px)` }}
          pagination={false}
          dataSource={rowsData}
          renderItem={(item: IDataConnection, _index) => {
            return (
              <StyledListItem key={item.id} color={item.source.nodeType?.color}>
                {projectInfo?.role !== UserProjectRole.Viewer && !isPublicPage && (
                  <Checkbox
                    className="all-data-checkbox"
                    style={{ marginRight: 16 }}
                    checked={isAllCheck || checkedItems.some((checkedItem) => checkedItem.id === item.id)}
                    onClick={() => handleItemClick(item)}
                  />
                )}

                <List.Item.Meta
                  title={
                    <Row align="middle">
                      <Col span={8}>
                        <Flex key={item.id} vertical>
                          <Flex align="center" onClick={() => dispatch(item)} style={{ cursor: 'pointer' }}>
                            <Flex gap={5} vertical style={{ maxWidth: 200 }}>
                              <Tooltip title={item.source.name} placement="bottom">
                                <Paragraph
                                  style={{ fontSize: 15, fontWeight: 600, color: '#222F6A', marginBottom: 0 }}
                                  ellipsis={{
                                    rows: 1,
                                    expandable: false,
                                  }}
                                >
                                  <Text color="#222F6A">{item.source.name}</Text>
                                </Paragraph>
                              </Tooltip>
                              <Text color="#414141" style={{ fontSize: 13, fontWeight: 500 }}>
                                {item.source.nodeType.name}
                              </Text>
                            </Flex>
                            <Flex style={{ width: 50, padding: '0px 10px' }} justify="center">
                              <ConnectionOneDirection fill={item.source.nodeType.color || ''} />
                            </Flex>
                            <Flex gap={5} vertical style={{ minWidth: 100 }}>
                              <Tooltip title={item.target.name} placement="bottom">
                                <Paragraph
                                  style={{ fontSize: 15, fontWeight: 600, color: '#222F6A', marginBottom: 0 }}
                                  ellipsis={{
                                    rows: 1,
                                    expandable: false,
                                  }}
                                >
                                  <Text color="#222F6A">{item.target.name}</Text>
                                </Paragraph>
                              </Tooltip>
                              <Text color="#414141" style={{ fontSize: 13, fontWeight: 500 }}>
                                {item.target.nodeType.name}
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Col>
                      <Col span={16}>
                        <Row justify="end" align="middle" gutter={[20, 20]}>
                          <Col span={3}>{item.status === 'draft' && <Draft>draft</Draft>}</Col>
                          <Col span={6}>
                            <TypeInfo color={item?.source?.nodeType.color} className="all-data-type-name">
                              <Text color={COLORS.PRIMARY.GRAY_DARK}>{item?.edgeTypes?.name}</Text>
                            </TypeInfo>{' '}
                          </Col>

                          <Col span={7}>
                            <Flex vertical gap={8}>
                              <Text color={COLORS.PRIMARY.GRAY}>
                                {item?.updated_at ? dayjs(item?.updated_at).format('DD MMMM [at] HH:mm') : ''}
                              </Text>
                              <Text color={COLORS.PRIMARY.BLUE}>
                                By {item?.created.first_name} {item?.created.last_name}
                              </Text>
                            </Flex>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }
                />
              </StyledListItem>
            );
          }}
        />
      </Spin>
      <RejectModal
        title=""
        text={
          modalAction === 'reject'
            ? 'Are you sure you wish to reject this data?'
            : 'Are you sure you wish to approve this data?'
        }
        isOpen={isOpenRejectModal}
        onClose={onHandleToggleDeleteModal}
        onFinish={onFinish}
      />
      <NodePagination
        total={count || 0}
        defaultPageSize={initPageData.size}
        pageSize={filterValue?.size || initPageData.size}
        defaultCurrent={initPageData.page}
        current={filterValue?.page || initPageData.page}
        onChange={(page, pageSize) => {
          setFilterValue((prev) => ({
            ...prev,
            page: page,
            size: pageSize,
          }));
        }}
        onShowSizeChange={(current, size) => {
          setFilterValue((prev) => ({
            ...prev,
            page: current,
            size: size,
          }));
        }}
      />
    </Wrapper>
  );
};
