import { Button, Checkbox, Flex, List } from 'antd';
import styled from 'styled-components';
import { TypeInfoProps } from 'types/edges';

export const Draft = styled.span`
  color: #383942;
  background: #f97070;
  border-radius: 18px;
  padding: 1px 10px;
  font-size: 15px;
`;
export const Wrapper = styled(Flex)`
  padding: 24px 0px 0px;
`;
export const CheckboGroup = styled(Checkbox.Group)`
  & .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
`;

export const NodeButton = styled(Button)`
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: 'center';
`;

export const StyledListItem = styled(({ color, ...props }) => <List.Item {...props} />)`
  && {
    margin-bottom: 8px;
    padding-left: 24px;
    padding-right: 32px;
    background: linear-gradient(90.12deg, rgba(255, 255, 255, 0.7) 1.63%, rgba(255, 255, 255, 0.2) 100%);
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      .all-data-type-name {
        background-color: ${(props) => `${props.color}`};
      }
    }
    .ant-list-item-meta {
      align-items: center;
    }
    .default-name-btn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: 35vw;
      .default-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const TypeInfo = styled(({ color, ...props }) => <div {...props} />)<TypeInfoProps>`
  background-color: ${(props) => `${props.color}15`};
  text-align: center;
  border-radius: 8px;
`;
