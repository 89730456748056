import React, { memo, useCallback } from 'react';
import { Divider, Flex, Form } from 'antd';
import { AppFormItem } from '../style';
import { FormItemHeader } from './form-item-header';
import { ReactComponent as RemoveIcon } from 'components/icons/remove.svg';
import { Button } from 'components/button';
import { TFormItemProps } from '../type';
import { FormItemType } from 'context/app-forms/initialState';
import { optionsAppFormItem } from './options';

export const FormItem: React.FC<TFormItemProps> = memo(
  ({
    id = '',
    isTouched,
    deleteId = '',
    name = '',
    type = FormItemType.PROPERTY,
    isEdges = false,
    onDelete,
    isLoading = false,
  }) => {
    // handle remove item with id
    const onHandleDelete = useCallback(
      (deletedId: string) => {
        onDelete(deletedId, id, isEdges ? FormItemType.EDGE : FormItemType.PROPERTY);
      },
      [id, isEdges, onDelete]
    );

    return (
      <AppFormItem isTouched={isTouched} isSaved={!!id} gap={16} vertical>
        <Flex justify="space-between" align="center">
          <FormItemHeader id={id} isTouched={isTouched} name={name} />
          {name !== 'name' && <RemoveIcon style={{ width: 16, height: 16 }} onClick={() => onHandleDelete(deleteId)} />}
          <Form.Item name="id" style={{ display: 'none' }} />
          <Form.Item name="property_name" style={{ display: 'none' }} />
          <Form.Item name="form_item_id" style={{ display: 'none' }} />
          <Form.Item name="form_item_type" initialValue={isEdges} style={{ display: 'none' }} />
        </Flex>
        <Divider style={{ margin: 0 }} />
        <Flex justify="space-between" align="center">
          {optionsAppFormItem[type as keyof typeof optionsAppFormItem]}
        </Flex>
        <Flex justify="end">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {id ? (isTouched ? 'Save change' : 'Update') : 'Save as'}
          </Button>
        </Flex>
      </AppFormItem>
    );
  }
);
