import React, { useCallback } from 'react';
import { ReactComponent as SaveAsSvg } from '../../icons/visualisation-save-as-action.svg';
import { useVisualizationHistory } from 'context/visualization-history';
import { ACTIONS } from 'context/visualization-history/reducer';

export const SaveVisualization: React.FC = () => {
  const { handleAction } = useVisualizationHistory();

  const onHandleToggleModal = useCallback(() => {
    handleAction({
      type: ACTIONS.OPEN_SAVE_MODAL,
      payload: {},
    });
  }, [handleAction]);

  return <SaveAsSvg onClick={onHandleToggleModal} />;
};
