import { Button, Form } from 'antd';
import { PlusAction } from 'components/actions/plus';
import { StyledButtonsWrapper, StyledDiv } from './styles';
import { ButtonsText } from './constant';
import { TButtonProps } from 'pages/project-visualisation/type';

const { SAVE_STYLING, ADD, RUN, CLEAN_ALL } = ButtonsText;

export const Buttons = ({ setOpenTable, resetFields, onHandleToggleModal, isHasPermissionSave }: TButtonProps) => {
  const form = Form.useFormInstance();
  const queries = Form.useWatch('queries', form);

  const isAddButtonDisabled = queries?.length >= 16;

  const handleClick = () => {
    setOpenTable(true);
  };

  return (
    <StyledButtonsWrapper>
      <StyledDiv>
        <Button name="clear-all-visualisation" htmlType="button" onClick={resetFields}>
          {CLEAN_ALL}
        </Button>
        <Button name="add-btn-visualisation" htmlType="button" onClick={handleClick} disabled={isAddButtonDisabled}>
          <PlusAction /> {ADD}
        </Button>
      </StyledDiv>
      <StyledDiv>
        <Button
          name="save-styling-visualisation"
          htmlType="button"
          disabled={!queries?.length || !isHasPermissionSave}
          onClick={onHandleToggleModal}
        >
          {SAVE_STYLING}
        </Button>
        <Button name="run-btn-visualisation" htmlType="submit">
          {RUN}
        </Button>
      </StyledDiv>
    </StyledButtonsWrapper>
  );
};
