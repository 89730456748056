/**
 * Represent visualization right bar settings
 *
 * @name VisualisatoinTools
 *
 * @see COMBO
 * @see RADIAL
 * @see FAZZY_MATCH
 * @see RESET
 * @see SAVE_AS
 * @see SHARE
 */

export enum VisualisatoinTools {
  COMBO = 'Combo',
  RADIAL = 'Radial',
  FAZZY_MATCH = 'Fazzy match',
  RESET = 'Reset',
  SAVE_AS = 'Save as ',
  SHARE = 'Share',
}
