import React from 'react';
import { Flex, Form, Radio, Tooltip } from 'antd';
import { Text } from 'components/typography';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';
import { VALIDATE_MESSAGES } from 'helpers/constants';

export const BooleanType: React.FC<AnyObject> = ({ id, question, hover, required }) => {
  const label = (
    <Flex gap={16} align="center">
      <Text>{question}</Text>
      {hover && (
        <Tooltip title={hover}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Flex>
  );

  return (
    <Form.Item label={label} name={id} rules={[{ required, message: VALIDATE_MESSAGES.required }]}>
      <Radio.Group>
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    </Form.Item>
  );
};
