import { useMutation } from '@tanstack/react-query';
import { RequestTypes } from 'api/types';
import { URL_APP_FORMS_EDGE_BY_ID, URL_APP_FORMS_PROPERTY_BY_ID } from './constant';
import client from 'api/client';
import { useParams } from 'react-router-dom';
import { TDeletePropertyData } from './types';
import { FormItemType } from 'context/app-forms/initialState';

/**
 * The mutation function to delete a form property.
 *
 * @param data - An object containing the `id` (id) and type [edge or property]
 */

export const useDeleteAppFormProperty = () => {
  const { id: projectId } = useParams();
  return useMutation({
    mutationFn: (data: TDeletePropertyData) => {
      const API_URL = data?.type === FormItemType.PROPERTY ? URL_APP_FORMS_PROPERTY_BY_ID : URL_APP_FORMS_EDGE_BY_ID;
      return client[RequestTypes.Delete](API_URL.replace(':project_id', projectId || '').replace(':id', data?.id));
    },
  });
};
