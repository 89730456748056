import { Flex } from 'antd';
import {
  GET_PUBLIC_GET_NODE_EDGE_TYPES_LIST,
  URL_GET_NODE_EDGE_TYPES_LIST_LEFT_MENU,
  useGetNodeEdgeTypes,
} from 'api/node-edge-type/use-get-node-edge-types';
import { DataSheetActionKind } from 'components/layouts/components/data-sheet/hooks/data-sheet-manage';
import { ConnectionOneDirection } from 'components/layouts/components/data-sheet/utils';
import { useDataSheetWrapper } from 'components/layouts/components/data-sheet/wrapper';
import { Text } from 'components/typography';
import { useIsPublicPage } from 'hooks/use-is-public-page';
import { useParams } from 'react-router-dom';
import { CheckboGroup } from './style';

export const ConnectionRejectTableSection = () => {
  const isPublicPage = useIsPublicPage();
  const { dispatch } = useDataSheetWrapper();
  const params = useParams();

  const { data } = useGetNodeEdgeTypes(
    {
      url: isPublicPage ? GET_PUBLIC_GET_NODE_EDGE_TYPES_LIST : URL_GET_NODE_EDGE_TYPES_LIST_LEFT_MENU,
      projectId: params.id || '',
    },
    {
      enabled: !!params.id,
    }
  );

  const handleChange = (checkedValue: unknown[]) => {
    const keys = checkedValue as string[];
    dispatch({
      type: DataSheetActionKind.SET_CONNECTIONS_LISTS,
      payload: { connectionLists: keys },
    });
  };

  const options = data?.map((item) => ({
    label: (
      <div style={{ position: 'relative', top: 3, padding: '6px 0px' }}>
        <Text color="#222F6A" style={{ fontWeight: 600 }}>
          {item.name}
        </Text>
        <Flex gap={5} align="center">
          <Text color="#414141" style={{ fontSize: 13, fontWeight: 500 }}>
            {item.source.name}
          </Text>
          <ConnectionOneDirection fill={item.source.color || ''} />
          <Text color="#414141" style={{ fontSize: 13, fontWeight: 500 }}>
            {item.target.name}
          </Text>
        </Flex>
      </div>
    ),
    value: item.id,
  }));

  return (
    <CheckboGroup
      onChange={handleChange}
      options={options}
      style={{ display: 'grid', paddingLeft: 24, maxHeight: `calc(91vh - ${170}px)`, overflow: 'auto' }}
    />
  );
};
