import React, { useCallback, useMemo, useState } from 'react';
import { Button, Divider, Flex, Select, Tooltip, Typography } from 'antd';
import { Text } from 'components/typography';
import { useGetAllVisualizationHistory } from 'api/visualization-history/use-get-all-visualization-history';
import { useDeleteVisualizationById } from 'api/visualization-history/use-delete-visualization-by-id';
import { VisualizationHistoryContainer } from './styles';
import { ReactComponent as RemoveIcon } from 'components/icons/remove-icon.svg';
import { COLORS } from 'helpers/constants';
import { TVisualHistorySelect } from './types';
import { useVisualizationHistory } from 'context/visualization-history';
import { ACTIONS } from 'context/visualization-history/reducer';
import { DeleteModal } from 'components/modal/delete-modal';

const { Paragraph } = Typography;

export const VisualizationHistory: React.FC = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState<string | undefined>(undefined);

  const { handleAction, visualizationId, hoverItem, userHasPermission } = useVisualizationHistory();

  const { data: histories, isLoading } = useGetAllVisualizationHistory();
  const { mutate: deleteHistoryFn } = useDeleteVisualizationById();

  const onHandleToggleDeleteModal = useCallback(() => setIsOpenDeleteModal((prev) => !prev), []);

  const onHandleToggleModal = useCallback(() => {
    handleAction({
      type: ACTIONS.CREATE_NEW_VISUALIZATION,
      payload: {},
    });
    handleAction({
      type: ACTIONS.OPEN_SAVE_MODAL,
      payload: {},
    });
  }, [handleAction]);

  const onHandleHoverItem = useCallback(
    (id: string) => {
      handleAction({
        type: ACTIONS.SET_HOVER_ITEM,
        payload: { id: id },
      });
    },
    [handleAction]
  );

  const onHandleSelectItem = useCallback(
    (option: TVisualHistorySelect) => {
      handleAction({
        type: ACTIONS.SELECT_HISTORY,
        payload: { id: option.value, name: option.name },
      });
    },
    [handleAction]
  );

  const onHandleClickRemoveItem = useCallback(
    (e: React.MouseEvent, id: string) => {
      e.stopPropagation();
      onHandleToggleDeleteModal();
      setDeletedId(id);
    },
    [onHandleToggleDeleteModal]
  );

  const onFinish = useCallback(() => {
    if (deletedId) {
      deleteHistoryFn(deletedId, {
        onSuccess: () => {
          if (deletedId === visualizationId) {
            handleAction({
              type: ACTIONS.RESET_VISUALIZATION,
              payload: {},
            });
          }
          setDeletedId(undefined);
          onHandleToggleDeleteModal();
        },
      });
    }
  }, [deleteHistoryFn, deletedId, handleAction, onHandleToggleDeleteModal, visualizationId]);

  const visualHistories = useMemo(() => {
    if (!histories) return [];

    const historyOptions = histories.map((visual) => ({
      value: visual.id,
      label: (
        <Flex justify="space-between" align="center" onMouseEnter={() => onHandleHoverItem(visual?.id)}>
          <Tooltip title={visual.name}>
            <Paragraph ellipsis={{ rows: 1 }} style={{ color: COLORS.PRIMARY.BLUE, marginBottom: 0 }}>
              {visual.name}
            </Paragraph>
          </Tooltip>
          {hoverItem === visual?.id && isOpenDrawer && userHasPermission() && (
            <RemoveIcon onClick={(e) => onHandleClickRemoveItem(e, visual.id)} />
          )}
        </Flex>
      ),
      name: visual.name,
    }));

    return [
      {
        value: 'main',
        label: (
          <Tooltip title="Main">
            <Text style={{ color: COLORS.PRIMARY.BLUE }}>Main</Text>
          </Tooltip>
        ),
        name: 'Main',
      },
      ...historyOptions,
    ];
  }, [histories, hoverItem, isOpenDrawer, onHandleClickRemoveItem, onHandleHoverItem, userHasPermission]);

  return (
    <>
      <VisualizationHistoryContainer>
        <Select
          options={visualHistories}
          defaultValue="main"
          value={visualizationId ?? 'main'}
          onDropdownVisibleChange={() => setIsOpenDrawer(!isOpenDrawer)}
          loading={isLoading}
          onChange={(_, option) => onHandleSelectItem(option as TVisualHistorySelect)}
          dropdownRender={(menu) => (
            <>
              <Text style={{ padding: 8, fontSize: 16 }}>History {`(${histories?.length || 0})`}</Text>
              <Divider style={{ margin: 8 }} />
              {menu}
              <Divider style={{ margin: 8 }} />
              {userHasPermission() && (
                <Button style={{ width: '100%' }} onClick={onHandleToggleModal}>
                  New +
                </Button>
              )}
            </>
          )}
        />
      </VisualizationHistoryContainer>
      <DeleteModal
        title="Delete Visualization"
        text="Are you sure you wish to permanently remove this visualization?"
        isOpen={isOpenDeleteModal}
        onClose={onHandleToggleDeleteModal}
        onFinish={onFinish}
      />
    </>
  );
};
