import { useQuery } from '@tanstack/react-query';
import client from 'api/client';
import { TNodeTypesProperties, TUseGetAllNodeTypeProperties } from 'api/types';
import { URL_GET_NODE_TYPES_PROPERTIES } from './constants';
import { useParams } from 'react-router-dom';

export const useGetNodeTypesProperty: TUseGetAllNodeTypeProperties = (params, options) => {
  const { id } = useParams();

  const { nodeTypeId, type, query } = params;

  let API_URL = URL_GET_NODE_TYPES_PROPERTIES.replace(':project_id', id || '').replace(':id', nodeTypeId);
  const results = useQuery<TNodeTypesProperties, Error>(
    [id, nodeTypeId],
    () => {
      if (type && query) {
        API_URL = API_URL.concat(`?${type}=`, query?.join(','));
      }
      return client.get(API_URL).then((res) => res.data);
    },
    {
      ...options,
    }
  );

  const { data, isLoading } = results;

  return {
    data,
    isLoading,
  };
};
