import { useMutation } from '@tanstack/react-query';
import client from 'api/client';
import { RequestTypes, TUseShareVisualizationParams } from 'api/types';
import { URL_CREATE_SHARE_VISUALIZATION } from './constant';
import { useParams } from 'react-router-dom';

export const useShareVisualization = () => {
  const { id: project_id } = useParams();
  return useMutation({
    mutationFn: (data: TUseShareVisualizationParams) => {
      return client[RequestTypes.Post](URL_CREATE_SHARE_VISUALIZATION, {
        project_id,
        ...data,
      });
    },
  });
};
