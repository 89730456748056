import { ReactComponent as RadialSvg } from './icons/radial.svg';
import { ReactComponent as ConcentricSvg } from './icons/concentric.svg';
import { ReactComponent as GridSvg } from './icons/grid.svg';
import { ReactComponent as CircularSvg } from './icons/circular.svg';
import { Menu } from 'antd';
import { IRadialMenuItemProps } from './types';

/** 
  @description visualisation Radial menu options
  @param setLayout update layout
*/

export const radialMenuItems = ({ setLayout }: IRadialMenuItemProps) => (
  <Menu
    items={[
      {
        key: 'radial',
        label: 'Radial',
        icon: <RadialSvg />,
        onClick: () => setLayout('radial'),
      },
      {
        key: 'concentric',
        label: 'Concentric',
        icon: <ConcentricSvg />,
        onClick: () => setLayout('concentric'),
      },
      {
        key: 'grid',
        label: 'Grid',
        icon: <GridSvg />,
        onClick: () => setLayout('grid'),
      },
      {
        key: 'circular',
        label: 'Circular',
        icon: <CircularSvg />,
        onClick: () => setLayout('circular'),
      },
    ]}
  />
);
