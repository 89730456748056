import React from 'react';
import { Flex } from 'antd';
import { useAppForms } from 'context/app-forms';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';
import { Search } from 'components/input/search';

export const Header: React.FC = () => {
  const { handleAction, params } = useAppForms();

  const handleChangeSearch = (text: string | undefined) => {
    if (!text?.length || text.length < 3) {
      text = undefined;
    }
    handleAction({
      type: APP_FORM_ACTIONS.CHANGE_PARAMS,
      payload: { ...params, search: text },
    });
  };

  return (
    <>
      <Flex justify="space-between" align="center">
        <Search style={{ width: 320 }} placeholder="Search forms..." onChange={handleChangeSearch} />
      </Flex>
    </>
  );
};
