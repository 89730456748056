import { useMutation, useQueryClient } from '@tanstack/react-query';
import client from 'api/client';
import { useParams } from 'react-router-dom';
import { IEgdeStatus } from 'api/types';
import { URL_GET_All_CONNECTION } from './use-get-all-connection';

const URL_GET_DRAFT_REJECT_CONNECTION = '/edges/status/:project_id';

export const useGetRejectApprowedConnection = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: IEgdeStatus) => {
      return client.put(URL_GET_DRAFT_REJECT_CONNECTION.replace(':project_id', id ?? ''), data);
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries([URL_GET_All_CONNECTION.replace(':project_id', id || '')]);
    },
  });
  return mutation;
};
