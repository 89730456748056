import { Layout, Menu } from 'antd';
import styled from 'styled-components';
import { COLORS } from 'helpers/constants';

const { Header: header, Content: content, Sider: sider } = Layout;

/**
 *  Represent External layouts style
 *
 *  @see LayoutExternal
 *  @see ExternalHeader
 *  @see ExternalContent
 */

export const LayoutExternal = styled(Layout)`
  width: 100%;
  height: 100%;
`;

export const ExternalHeader = styled(header)`
  background: transparent;
  padding: 20px 50px;
  height: 90px;
  box-shadow: 10px 6px 10px rgba(111, 111, 111, 0.1);
`;

export const ExternalContent = styled(content)`
  padding: 0;
  position: relative;
  min-height: 100vh;
`;

/**
 *  Represent Admin layouts style
 *
 *  @see LayoutAdmin
 *  @see AdminHeader
 *  @see ContainerAdmin
 *  @see Sider
 */

export const LayoutAdmin = styled(Layout)`
  background: ${COLORS.MAIN_GRAY};
  height: 100%;
  min-height: 100vh;
`;

export const ContainerAdmin = styled(Layout)`
  background: ${COLORS.MAIN_GRAY};
  box-shadow: -10px 0px 10px rgba(111, 111, 111, 0.1);
  border-radius: 4px;
`;

export const AdminHeader = styled(header)`
  background: transparent;
  padding: 20px 50px;
  height: 90px;
  box-shadow: 10px 6px 10px rgba(111, 111, 111, 0.1);
`;

export const Sider = styled(sider)`
  background: transparent;
`;

export const AdminMenu = styled(Menu)`
  background: transparent;

  .ant-menu-item {
    margin: 0;
    width: 100%;
    height: 56px;

    &.ant-menu-item-selected {
      background: ${COLORS.PRIMARY.GRAY_DARK};

      .ant-typography {
        color: ${COLORS.PRIMARY.WHITE};
      }

      svg {
        fill: ${COLORS.PRIMARY.WHITE};
      }
    }
  }
`;

export const AdminContent = content;

/**
 *  Represent Overview layouts style
 *
 *  @see OverviewLayout
 *  @see OverviewHeader
 *  @see OverivewContent
 */

export const OverviewLayout = styled(Layout)`
  background: ${COLORS.MAIN_GRAY};
  height: 100%;
  position: relative;
  min-height: 100vh;
`;

export const OverviewHeader = styled(header)`
  && {
    background: transparent;
    padding: 12px;
    @media (min-width: 1200px) {
      padding: 32px 32px 15px;
    }
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const OverivewContent = styled(content)`
  padding: 0;
`;

/**
 *  Represent Project layouts style
 *
 *  @see ProjectHomeLayout
 *  @see ProjectHomeMenu
 *  @see ProjectHomeContainer
 *  @see ProjectHomeHeader
 *  @see ProjectHomeContent
 */

export const ProjectHomeLayout = styled(Layout)`
  background: ${COLORS.MAIN_GRAY};
  height: 100%;
  min-height: 100vh;
`;

export const ProjectHomeMenu = styled(Menu)`
  background: transparent;

  .ant-menu-item {
    margin: 0;
    width: 100%;
    height: 56px;
    align-items: center;
    display: flex;

    &.ant-menu-item-selected {
      background: ${COLORS.PRIMARY.GRAY_DARK};

      .ant-typography {
        color: ${COLORS.PRIMARY.WHITE};
      }

      svg {
        fill: ${COLORS.PRIMARY.WHITE};
      }
    }
  }
`;

export const ProjectHomeContainer = styled(Layout)`
  background: ${COLORS.MAIN_GRAY};
  box-shadow: -10px 0px 10px rgba(111, 111, 111, 0.1);
  border-radius: 4px;
`;

export const ProjectHomeHeader = styled(header)`
  background: transparent;
  box-shadow: 10px 6px 10px rgba(111, 111, 111, 0.1);
`;

export const ProjectHomeContent = styled(content)`
  padding: 20px;
`;

export const ProjectHomeSider = styled(Sider)`
  background: transparent;
`;
