import { Flex } from 'antd';
import { COLORS } from 'helpers/constants';
import styled from 'styled-components';

export const AppForm = styled(Flex)`
  width: 100%;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  border: 1px solid ${COLORS.MAIN_GRAY_SILVER};
  background-color: ${COLORS.PRIMARY.WHITE};
  & .application-form {
    padding: 16px;
    width: 50%;
  }
  & button {
    width: 100%;
  }
`;

export const HeaderForm = styled(Flex)<{ url: string | null }>`
  width: 100%;
  min-height: 150px;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => (props?.url ? process.env.REACT_APP_AWS_URL?.concat('', props?.url) : '')});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
