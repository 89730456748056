import { useCallback, useState } from 'react';
import { PATHS } from 'helpers/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'context/auth-context';
import { UseToolsetHandlers } from 'types/jira-integration';
import { useRedirectToJira } from 'api/jira/use-redirect-to-jira';
import { useJira } from '../components/jira/context';

export const useToolsetHandlers: UseToolsetHandlers = () => {
  const { setOpenToolset } = useJira();
  const params = useParams();

  const [isJira, setIsJira] = useState(false);

  const [accessRequest, setAccessRequest] = useState<boolean>(false);
  const navigate = useNavigate();

  const { user } = useAuth();

  useRedirectToJira({
    enabled: accessRequest,
    onSuccess: (data) => {
      location.href = data.data.url;
    },
  });

  /**
   * @function handleAnalyticsClick
   * @description Handles the navigation to the project analytics page and closes the toolset panel.
   *
   * @returns {void}
   *
   * @example
   * handleAnalyticsClick();
   */

  const handleAnalyticsClick = useCallback(() => {
    void navigate(PATHS.PROJECT_ANALYTICS.replace(':id', params.id || ''));
    setOpenToolset(false);
  }, [navigate, params.id, setOpenToolset]);

  /**
   * @function handleDocumentClick
   * @description Handles the navigation to the project documents page and closes the toolset panel.
   *
   * @returns {void}
   *
   * @example
   * handleDocumentClick();
   */

  const handleDocumentClick = useCallback(() => {
    void navigate(PATHS.PROJECT_DOCUMENTS.replace(':id', params.id || ''));
    setOpenToolset(false);
  }, [navigate, params.id, setOpenToolset]);

  /**
   * @function handleAppFormsClick
   * @description Handles the navigation to the project app forms page and closes the toolset panel.
   *
   * @returns {void}
   *
   * @example
   * handleAppFormsClick();
   */

  const handleAppFormsClick = useCallback(() => {
    void navigate(PATHS.APP_FORM_CREATE.replace(':id', params.id || ''));
    setOpenToolset(false);
  }, [params.id, navigate, setOpenToolset]);

  const handleJiraClick = useCallback(() => {
    if (user?.jira_account_id) {
      setIsJira(!isJira);
    } else {
      setAccessRequest(true);
    }
  }, [isJira, setIsJira, user]);

  return {
    isJira,
    handleAnalyticsClick,
    handleDocumentClick,
    handleAppFormsClick,
    handleJiraClick,
  };
};
