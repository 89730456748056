import React from 'react';
import { AppFormsProvider } from 'context/app-forms';
import { AppFormView } from './app-form-view';

export const AppForms: React.FC = () => {
  return (
    <AppFormsProvider>
      <AppFormView />
    </AppFormsProvider>
  );
};
