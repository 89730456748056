import { Logo } from 'components/logo';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ExternalContent, ExternalHeader, LayoutExternal } from './styles';

export const ExternalLayout: React.FC = () => {
  return (
    <LayoutExternal>
      <ExternalHeader>
        <Logo />
      </ExternalHeader>
      <ExternalContent>
        <Outlet />
      </ExternalContent>
    </LayoutExternal>
  );
};
