import { useMutation, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { errorMessage } from 'helpers/utils';
import { useParams } from 'react-router-dom';

import client from '../client';
import {
  URL_DELETE_PROJECT_ALL_CONNECTION,
  URL_DELETE_PROJECT_ALL_DATA_CHECKED,
  URL_GET_PROJECT_ALL_DATA,
} from './constants';
import { URL_GET_All_CONNECTION } from 'api/edges/use-get-all-connection';

export const useDeleteAllDataChecked = (checkedIds: string[], options?: UseQueryOptions, checkText?: string) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const deleteUrl = checkText ? URL_DELETE_PROJECT_ALL_CONNECTION : URL_DELETE_PROJECT_ALL_DATA_CHECKED;
  const deleteUrlSuccess = checkText ? URL_GET_All_CONNECTION : URL_GET_PROJECT_ALL_DATA;
  const mutation = useMutation({
    mutationFn: () =>
      client.delete(deleteUrl.replace(':project_id', params.id || ''), {
        data: { ids: checkedIds },
      }),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([deleteUrlSuccess.replace(':project_id', params.id || '')]);

      options?.onSuccess?.(data);
    },

    onError: errorMessage,
  });
  return mutation;
};
