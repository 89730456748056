import { Button, Col, Flex, Row } from 'antd';
import { Sort } from 'components/dropdown';
import { ALL_DATA_SORT_BY } from 'components/dropdown/constants';
import { ExpandableInput, SearchText } from 'components/input/expandable-input';
import { DeleteAllDataModal } from 'components/modal/delete-all-data-modal';
import { useSort } from 'context/sort-context';
import { DEFAULT_PAGE_NUMBER, ROLES_PROJECT, STATUS_PROJECT } from 'helpers/constants';
import { useIsPublicPage } from 'hooks/use-is-public-page';
import { Dispatch, memo, SetStateAction, useCallback, useEffect, useState } from 'react';
import { defaultAllDataFilter } from '../right-section-all-data';
import { DownloadAction } from 'components/actions';
import { ReactComponent as SourceSvg } from 'components/icons/source.svg';
import { NodeCreateDrawer } from 'pages/project-visualisation/components/drawers/nodes/create-node';
import styled from 'styled-components';
import { useGetTypes } from 'api/schema/type/use-get-types';
import { useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { RightSectionAllDataProps } from 'api/types';
import { useGetRejectApprowed } from 'api/schema/type/use-get-draft';
import { RejectModal } from 'components/modal/reject-node-modal';

type Props = {
  checkedItems: RightSectionAllDataProps[];
  filterValue: typeof defaultAllDataFilter;
  setCheckedItems: (checkedItems: RightSectionAllDataProps[]) => void;
  setIsAllCheck: Dispatch<SetStateAction<boolean>>;
  setFilterValue: (
    filter: typeof defaultAllDataFilter | ((prevVar: typeof defaultAllDataFilter) => typeof defaultAllDataFilter)
  ) => void;
  onOpenSource: (value: boolean) => void;
};

const NodeButton = styled(Button)`
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: 'center';
`;

export const AllDataFilterSection = memo(
  ({ setFilterValue, checkedItems, setCheckedItems, setIsAllCheck, filterValue, onOpenSource }: Props) => {
    const [open, setOpen] = useState(false);
    const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
    const [modalAction, setModalAction] = useState<'reject' | 'approve'>('reject');

    const onHandleToggleDeleteModal = useCallback(() => setIsOpenRejectModal((prev) => !prev), []);

    const checkedDraft = checkedItems.some((item: { status: string }) => item.status === 'draft');
    const checkedId = checkedItems.map((item: { id: string }) => item.id);

    const { state: sortState } = useSort();
    const isPublicPage = useIsPublicPage();
    const { id } = useParams();

    // permissions status project
    const permissions = localStorage.getItem('permissions');
    const GetPermissions = permissions ? JSON.parse(permissions) : null;
    const permissionsStatus = GetPermissions?.data.status;
    const permissionsRole = GetPermissions?.data.role;

    const hasEditPermissions =
      permissionsStatus === STATUS_PROJECT.MAIN &&
      (permissionsRole === ROLES_PROJECT.EDIT || permissionsRole === ROLES_PROJECT.OWNER);

    const { nodes } = useGetTypes({ projectId: id ?? '' });
    const { mutate: updateReject } = useGetRejectApprowed();

    const updateRejectStatus = (action: 'reject' | 'approve') => {
      setModalAction(action);
      setIsOpenRejectModal(true);
    };

    const onFinish = useCallback(() => {
      const status = modalAction === 'reject' ? 'rejected' : 'approved';

      updateReject(
        {
          ids: checkedId,
          status: status,
        },
        {
          onSuccess: () => {
            setCheckedItems([]);
          },
        }
      );

      setIsOpenRejectModal(false);
    }, [checkedId, modalAction, updateReject, setCheckedItems]);

    const setSearchText = useCallback(
      ({ text, type, allowDocumentCount, is_documents, is_drafts }: SearchText) => {
        setFilterValue((prevValue) => ({
          ...prevValue,
          search: text,
          type,
          allowDocumentCount,
          is_documents,
          is_drafts,
          page: DEFAULT_PAGE_NUMBER,
          ...(isPublicPage && type === 'document' ? { isPublic: true } : {}),
        }));
      },
      [isPublicPage, setFilterValue]
    );

    useEffect(() => {
      const [orderName, order] = sortState?.split(' ') || [];

      if (orderName && order) {
        setFilterValue((prev) => ({
          ...prev,
          allowDocumentCount: false,
          sortField: orderName,
          sortOrder: order,
        }));
      }
    }, [setFilterValue, sortState]);

    return (
      <Row justify="space-between" style={{ padding: '24px 32px 32px 24px' }} gutter={[20, 10]}>
        <Col span={16}>
          <Row gutter={[20, 10]}>
            <Col>
              <Sort prefix="Sort By:" sortItems={ALL_DATA_SORT_BY} fullWidth />
            </Col>
            <Col>
              <ExpandableInput setSearchText={setSearchText} />
            </Col>
          </Row>
        </Col>
        <Row justify="end" gutter={[20, 10]}>
          {filterValue.project_type_list_id?.length ? (
            <Col>
              <DownloadAction nodeTypeIds={filterValue.project_type_list_id} />
            </Col>
          ) : undefined}
          <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
            <DeleteAllDataModal
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              onSubmit={() => {
                setIsAllCheck(false);
              }}
            />
          </Col>
          {!isPublicPage ? (
            <>
              <NodeButton onClick={() => setOpen(true)} type="primary" disabled={nodes?.length === 0}>
                + Add Node
              </NodeButton>
              <Col>
                {hasEditPermissions && <SourceSvg style={{ cursor: 'pointer' }} onClick={() => onOpenSource(true)} />}
              </Col>
            </>
          ) : null}
          <NodeCreateDrawer alternativeOpen={open} alternativeClosed={setOpen} />
        </Row>

        {checkedDraft && permissionsRole === ROLES_PROJECT.OWNER ? (
          <Flex gap={16} style={{ position: 'absolute', top: 80, right: 22 }}>
            <NodeButton type="primary" onClick={() => updateRejectStatus('approve')}>
              <CheckOutlined />
              Approve
            </NodeButton>
            <NodeButton onClick={() => updateRejectStatus('reject')}>
              <CloseOutlined />
              Reject
            </NodeButton>
          </Flex>
        ) : null}

        <RejectModal
          title=""
          text={
            modalAction === 'reject'
              ? 'Are you sure you wish to reject this data?'
              : 'Are you sure you wish to approve this data?'
          }
          isOpen={isOpenRejectModal}
          onClose={onHandleToggleDeleteModal}
          onFinish={onFinish}
        />
      </Row>
    );
  }
);
