import { useQuery } from '@tanstack/react-query';
import client from 'api/client';
import { TUseGetAllPermissionItems } from 'api/types';

export const GET_PROJECT_PERMISSION = 'projects/permissions/:id';

export const useGetProjecTPermission: TUseGetAllPermissionItems = (id, options) => {
  const url = GET_PROJECT_PERMISSION.replace(':id', id);

  const result = useQuery({
    queryKey: [url],
    queryFn: async () => {
      const response = await client.get(url);
      return response.data;
    },
    ...options,
  });

  const { data, isLoading } = result;

  return {
    data,
    isLoading,
  };
};
