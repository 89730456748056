import React, { memo } from 'react';
import { Checkbox } from '../index';

interface ICheckBox {
  label: string | React.ReactNode;
  value: string;
}

type TConnectionListProps = {
  options: Array<ICheckBox>;
  onChange: (value: string[]) => void;
  style: React.CSSProperties;
  isDisabled?: boolean;
  value?: string[];
};

export const CheckBoxGroups: React.FC<TConnectionListProps> = memo(
  ({ options, onChange, style, isDisabled, value }) => {
    const handleChange = (keys: string[]) => {
      onChange(keys);
    };

    return (
      <Checkbox.Group value={value} disabled={isDisabled} options={options} onChange={handleChange} style={style} />
    );
  }
);
