import { MenuProps, Space } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { PATHS } from 'helpers/constants';
import { Logo } from 'components/logo';
import { HeaderProfile } from 'components/header-profile';
import { AdminContent, AdminHeader, AdminMenu, ContainerAdmin, LayoutAdmin, Sider } from './styles';
import { adminMenu } from './constant';

export const AdminLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key);
  };

  const defaultSelected = location.pathname === PATHS.ADMIN ? PATHS.ADMIN : PATHS.ADMIN_MANAGEMENT;

  return (
    <LayoutAdmin>
      <Sider width={300} breakpoint="lg">
        <Logo margin="31px 63px" />
        <AdminMenu
          mode="inline"
          inlineIndent={63}
          selectedKeys={[defaultSelected]}
          onClick={onClick}
          items={adminMenu}
        />
      </Sider>
      <ContainerAdmin>
        <AdminHeader>
          <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <HeaderProfile />
            <text>Admin</text>
          </Space>
        </AdminHeader>
        <AdminContent>
          <Outlet />
        </AdminContent>
      </ContainerAdmin>
    </LayoutAdmin>
  );
};
