export const URL_NODES_LIST = '/nodes/list/:project_id/:project_type_id';
export const URL_PUBLIC_NODES_LIST = '/public/nodes-list/:project_id/:project_type_id';
export const URL_CREATE_NODE = '/nodes/create';
export const URL_UPDATE_NODE = '/nodes/update/:id';
export const URL_GET_NODE = '/nodes/:id';
export const URL_DELETE_NODE = '/nodes/delete/:id';
export const URL_GET_CONNECTION_SOURCE_LIST = '/nodes/data-by-type/:project_id/:project_type_id';
export const URL_GET_EXTERNAL_SHARE_NODE_VIEW = '/external/graphs/nodes/:id';
export const URL_GET_NODE_TYPES_PROPERTIES = '/node-type-property/:project_id/:id';
export const URL_GET_NODE_TYPES = '/projects-node-types/main/:project_id';
