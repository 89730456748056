import { memo, useCallback } from 'react';
import { Form, Input, InputNumber, DatePicker } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { useAppForms } from 'context/app-forms';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';
import { dateFormat } from 'helpers/constants';
import { removeTextSpaceDependOnLength } from 'helpers/utils';

const { RangePicker } = DatePicker;

enum Options {
  QUESTION = 'question',
  PLACEHOLDER = 'placeholder',
  HOVER = 'hover',
  REQUIRED = 'required',
}

const Question = () => {
  const { handleAction } = useAppForms();

  const form = Form.useFormInstance();

  const id = Form.useWatch('form_item_id');

  const type = Form.useWatch('form_item_type');

  const onChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      // remove space question
      const value = await removeTextSpaceDependOnLength(e.target.value);
      form.setFieldsValue({ [Options.QUESTION]: value });

      handleAction({
        type: APP_FORM_ACTIONS.CHANGE_FORM_ITEM_VISUAL,
        payload: { id, text: value, type: Options.QUESTION, itemType: type },
      });
    },
    [form, handleAction, id, type]
  );

  return (
    <Form.Item name={Options.QUESTION} label="Question" rules={[{ required: true, min: 1, max: 120 }]}>
      <Input placeholder="Question" onChange={onChange} />
    </Form.Item>
  );
};

const PlaceholderText = () => {
  const { handleAction } = useAppForms();

  const form = Form.useFormInstance();

  const id = Form.useWatch('form_item_id');
  const type = Form.useWatch('form_item_type');

  const onChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      // remove space placeholder
      const value = await removeTextSpaceDependOnLength(e.target.value);
      form.setFieldsValue({ [Options.PLACEHOLDER]: value });

      handleAction({
        type: APP_FORM_ACTIONS.CHANGE_FORM_ITEM_VISUAL,
        payload: { id, text: value, type: Options.PLACEHOLDER, itemType: type },
      });
    },
    [form, handleAction, id, type]
  );

  return (
    <Form.Item name={Options.PLACEHOLDER} label="Placeholder Text">
      <Input placeholder="Placeholder text" onChange={onChange} />
    </Form.Item>
  );
};

const HoverText = () => {
  const { handleAction } = useAppForms();

  const form = Form.useFormInstance();

  const id = Form.useWatch('form_item_id');
  const type = Form.useWatch('form_item_type');

  const onChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      // remove space hover text
      const value = await removeTextSpaceDependOnLength(e.target.value);
      form.setFieldsValue({ [Options.HOVER]: value });

      handleAction({
        type: APP_FORM_ACTIONS.CHANGE_FORM_ITEM_VISUAL,
        payload: { id, text: value, type: Options.HOVER, itemType: type },
      });
    },
    [form, handleAction, id, type]
  );

  return (
    <Form.Item name={Options.HOVER} label="Hover Text">
      <Input placeholder="Hover Text" onChange={onChange} />
    </Form.Item>
  );
};

const MinAndMax = memo(({ min, max }: { min: number; max: number }) => {
  return (
    <>
      <Form.Item name="min" label="Min" initialValue={min}>
        <InputNumber min={min} placeholder="Min" />
      </Form.Item>
      <Form.Item name="max" label="Max" initialValue={max}>
        <InputNumber min={min} max={max} placeholder="Max" />
      </Form.Item>
    </>
  );
});

/**
 * @description when type date or datetime
 *
 * @returns JSX.Element
 */

const RangeDate = () => (
  <Form.Item name="betweenDate" label="Date">
    <RangePicker format={dateFormat} />
  </Form.Item>
);

/**
 * @description when property name is 'name' need to be true
 *
 * @returns JSX Element
 */

const Required = () => {
  const { handleAction } = useAppForms();

  const propertyName = Form.useWatch('property_name');
  const isDisabled = propertyName === 'name';

  const id = Form.useWatch('project_type_property_id');
  const type = Form.useWatch('form_item_type');

  const onChange = useCallback(
    (e: CheckboxChangeEvent) => {
      handleAction({
        type: APP_FORM_ACTIONS.CHANGE_FORM_ITEM_VISUAL,
        payload: { id: id, text: e.target.checked, type: Options.REQUIRED, itemType: type },
      });
    },
    [handleAction, id, type]
  );

  return (
    <Form.Item name={Options.REQUIRED} label="Required" valuePropName="checked">
      <Checkbox style={{ transform: 'scale(1.5)' }} onChange={onChange} disabled={isDisabled} />
    </Form.Item>
  );
};

/**
 * @description That function render component with params when isShow true show min max component
 *
 * @param isShow
 *
 */

const RenderOptionsByTerms = memo(({ isShow = false, isDate = false }: { isShow?: boolean; isDate?: boolean }) => (
  <>
    {<Question />}
    {<PlaceholderText />}
    {<HoverText />}
    {isShow && <MinAndMax min={1} max={120} />}
    {isDate && <RangeDate />}
    {<Required />}
  </>
));

export const optionsAppFormItem: AnyObject = {
  text: <RenderOptionsByTerms isShow={true} />,
  integer: <RenderOptionsByTerms isShow={true} />,
  datetime: <RenderOptionsByTerms isDate={true} />,
  date: <RenderOptionsByTerms isDate={true} />,
  decimal: <RenderOptionsByTerms isShow={true} />,
  document: <RenderOptionsByTerms />,
  location: <RenderOptionsByTerms />,
  rich_text: <RenderOptionsByTerms />,
  url: <RenderOptionsByTerms />,
  image_url: <RenderOptionsByTerms />,
  enum: <RenderOptionsByTerms />,
  boolean: <RenderOptionsByTerms />,
  connection: <RenderOptionsByTerms />,
};
