import React, { memo } from 'react';
import { Flex, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useDeleteShareVisualization } from 'api/visualisation/use-delete-shared-visualization';
import { PATHS } from 'helpers/constants';
import { ISharedLinkProps } from 'pages/project-visualisation/type';
import { CopyLink } from 'components/actions/copy-link';

export const ShareLink: React.FC<ISharedLinkProps> = memo(({ data, onSetSharedData }) => {
  const { mutate: deleteShareFn } = useDeleteShareVisualization();

  const onHandleDeleteShare = () => {
    deleteShareFn(data?.id, {
      onSuccess: () => {
        message.success('Link deleted successfully!');
        onSetSharedData(null);
      },
    });
  };

  return (
    <Flex style={{ width: '100%' }} align="center" justify="space-between">
      <CopyLink path={`${PATHS.PROJECT_SHARE_VISUALIZATION.replace(':id', data?.id)}?token=${data?.token}`} />
      <DeleteOutlined onClick={onHandleDeleteShare} style={{ cursor: 'pointer' }} />
    </Flex>
  );
});
