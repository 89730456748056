import { TInitialState, TVisualizationHistoryActions } from './types';

export const ACTIONS = {
  SELECT_HISTORY: 'SELECT_HISTORY',
  OPEN_SAVE_MODAL: 'OPEN_SAVE_MODAL',
  RESET_VISUALIZATION: 'RESET_VISUALIZATION',
  SET_HOVER_ITEM: 'SET_HOVER_ITEM',
  SET_STYLES_DATA: 'SET_STYLES_DATA',
  REMOVE_QUERY_ITEM: 'REMOVE_QUERY_ITEM',
  CREATE_NEW_VISUALIZATION: 'CREATE_NEW_VISUALIZATION',
  SET_VISUALIZATION_USER: 'SET_VISUALIZATION_USER',
  UPDATE_FILTERS_LIST_VISUALIZATION: 'UPDATE_FILTERS_LIST_VISUALIZATION',
  OPEN_SHARE_DRAWER: 'OPEN_SHARE_DRAWER',
  SET_SHARED_DATA: 'SET_SHARED_DATA',
} as const;

export const reducer = (state: TInitialState, action: TVisualizationHistoryActions) => {
  const { type, payload } = action;
  switch (type) {
    /**
     * @description choose visualization
     * @param id
     * @param name
     * @returns state
     */

    case ACTIONS.SELECT_HISTORY: {
      const isMain = payload.id === 'main';
      return {
        ...state,
        visualizationId: isMain ? undefined : payload.id,
        name: isMain ? undefined : payload.name,
        reset: isMain,
        queriesStyle: undefined,
        sharedData: null,
      };
    }

    /**
     * @description toggle modal create new visualization
     * @param none
     * @returns state
     */

    case ACTIONS.OPEN_SAVE_MODAL: {
      return {
        ...state,
        openSaveModal: !state.openSaveModal,
      };
    }

    /**
     * @description reset visualization
     * @param none
     * @returns state
     */

    case ACTIONS.RESET_VISUALIZATION: {
      return {
        ...state,
        name: undefined,
        visualizationId: payload.isShared ? state?.visualizationId : undefined,
        openSaveModal: false,
        reset: !state.reset,
        queriesStyle: undefined,
        sharedData: null,
        filterCheckedList: [],
      };
    }

    /**
     * @description  set hover item
     * @param none
     * @returns state
     */

    case ACTIONS.SET_HOVER_ITEM: {
      return {
        ...state,
        hoverItem: payload.id,
      };
    }

    /**
     * @description set queryArr in styles left bar when click run
     * @param array values.query
     * @type Array
     * @returns state
     */

    case ACTIONS.SET_STYLES_DATA: {
      return {
        ...state,
        queriesStyle: payload,
      };
    }

    /**
     * @description when click new button visualization
     * @param array
     * @type Array
     * @returns state
     */

    case ACTIONS.CREATE_NEW_VISUALIZATION: {
      return {
        ...state,
        visualizationId: undefined,
        name: undefined,
      };
    }

    /**
     * @description when click x button remove item current
     * @param filed number
     * @returns state
     */

    case ACTIONS.REMOVE_QUERY_ITEM: {
      let queryStyles = state.queriesStyle;
      if (state.queriesStyle) {
        queryStyles = state.queriesStyle.filter((_, index) => index !== payload);
      }
      return {
        ...state,
        queryStyles,
      };
    }

    /**
     * @description set visualization userId
     * @param user_id
     * @returns state
     */

    case ACTIONS.SET_VISUALIZATION_USER: {
      return {
        ...state,
        userId: payload.user_id,
      };
    }

    /**
     * @description update visualization filters list
     * @param checkedList
     * @returns state
     */

    case ACTIONS.UPDATE_FILTERS_LIST_VISUALIZATION: {
      return {
        ...state,
        filterCheckedList: payload,
      };
    }

    /**
     * @description open visualization share drawer
     * @param boolean
     * @returns state
     */

    case ACTIONS.OPEN_SHARE_DRAWER: {
      return {
        ...state,
        openShareDrawer: payload,
      };
    }

    /**
     * @description set visualization share data
     * @param object
     * @returns state
     */

    case ACTIONS.SET_SHARED_DATA: {
      return {
        ...state,
        sharedData: payload,
      };
    }

    default:
      return state;
  }
};
