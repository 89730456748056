import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';
import { COLORS } from 'helpers/constants';
import { Input } from './styles';

interface ISearch {
  placeholder?: string;
  style: AnyObject;
  onChange: (val: string) => void;
}

export const Search: React.FC<ISearch> = ({ placeholder = 'Search...', style, onChange }) => {
  return (
    <Input
      onChange={(e) => onChange(e.target.value)}
      addonBefore={<SearchOutlined style={{ color: COLORS.MAIN_GRAY_SILVER }} />}
      style={style}
      placeholder={placeholder}
    />
  );
};
