import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { URL_GET_LEFT_BAR_DATA } from './constant';
import client from 'api/client';
import { RequestTypes } from 'api/types';
import { TUseGetAppFormLeftBar, LeftBarData } from './types';

export const useGetAppFormLeftBar: TUseGetAppFormLeftBar = (nodeTypeId, options) => {
  const { id: projectId } = useParams();
  const API_URL = URL_GET_LEFT_BAR_DATA.replace(':project_id', projectId || '').replace(':id', nodeTypeId);

  const results = useQuery<LeftBarData, Error>(
    [API_URL],
    async () => {
      const response = await client[RequestTypes.GET](API_URL);
      return response.data;
    },
    options
  );

  return {
    data: results.data ?? ({} as LeftBarData),
    isLoading: results.isLoading,
  };
};
