import { Drawer } from 'antd';
import { useAppForms } from 'context/app-forms';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';
import React, { useCallback } from 'react';
import { FormView } from '../form-view';
import { COLORS } from 'helpers/constants';

export const AppFormView: React.FC = () => {
  const { handleAction, isShowView, form } = useAppForms();

  const { background_image, id } = form;

  const onClose = useCallback(() => {
    handleAction({
      type: APP_FORM_ACTIONS.SHOW_VIEW,
      payload: false,
    });
  }, [handleAction]);

  return (
    <Drawer
      style={{ background: COLORS.MAIN_GRAY }}
      title={form?.name}
      open={isShowView}
      width={'100%'}
      onClose={onClose}
      getContainer={false}
      forceRender={true}
    >
      <FormView data={form} url={background_image} appFormId={id} isSubmit={false} />
    </Drawer>
  );
};
