import { ReactComponent as RepositorySvg } from '../icons/repository.svg';
import { ReactComponent as AnalysisSvg } from '../icons/analysis.svg';
import { ReactComponent as JiraSvg } from 'components/icons/jira.svg';
import { ReactComponent as AppFormsSvg } from 'components/icons/app-forms.svg';
import { ReactNode } from 'react';
import {
  ANALYTICS_DESCRIPTION,
  ANALYTICS_TITLE,
  DOCUMENT_REPOSITORY_DESCRIPTION,
  DOCUMENT_REPOSITORY_TITLE,
  APP_FORMS_TITLE,
  APP_FORMS_DESCRIPTION,
  JIRA_CONNECTED_TITLE,
  JIRA_DESCRIPTION,
  JIRA_NOT_CONNECTED_TITLE,
} from './constants';
import { ToolSetDataParams } from 'types/jira-integration';

const createToolItem = (title: string, description: string, icon: ReactNode, handleClick?: VoidFunction) => ({
  title,
  description,
  icon,
  handleClick,
});

export const toolSetDataHandle: ToolSetDataParams = ({
  handleAnalyticsClick,
  handleDocumentClick,
  handleAppFormsClick,
  handleJiraClick,
  isJiraConnected,
}) => [
  createToolItem(ANALYTICS_TITLE, ANALYTICS_DESCRIPTION, <AnalysisSvg />, handleAnalyticsClick),
  createToolItem(DOCUMENT_REPOSITORY_TITLE, DOCUMENT_REPOSITORY_DESCRIPTION, <RepositorySvg />, handleDocumentClick),
  createToolItem(APP_FORMS_TITLE, APP_FORMS_DESCRIPTION, <AppFormsSvg />, handleAppFormsClick),
  createToolItem(
    isJiraConnected ? JIRA_CONNECTED_TITLE : JIRA_NOT_CONNECTED_TITLE,
    isJiraConnected ? JIRA_DESCRIPTION : '',
    <JiraSvg />,
    handleJiraClick
  ),
];
