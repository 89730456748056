import React, { useState } from 'react';
import { Button, Form, Input, Upload } from 'antd';
import { useAppForms } from 'context/app-forms';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';
import { Text } from 'components/typography';
import { useCreateAppForms } from 'api/app-forms/use-create-app-forms';
import { PATHS, StatusActionType } from 'helpers/constants';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { AnyObject } from 'antd/es/_util/type';
import { CreateAppFormContent } from '../style';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;

export const CreateForm: React.FC = () => {
  const { nodeTypeId, handleAction } = useAppForms();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { mutateAsync: createAsyncFn } = useCreateAppForms();

  const naviagate = useNavigate();

  const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };

  const beforeUpload = (file: UploadFile) => {
    setFileList([...fileList, file]);
    return false;
  };

  const handleFinish = async (values: AnyObject) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('project_type_id', nodeTypeId);
    formData.append('status', StatusActionType.DISABLE);

    fileList.forEach((file) => {
      formData.append('file', file.originFileObj as Blob);
    });

    await createAsyncFn(formData, {
      onSuccess: (data) => {
        const { data: appformData } = data;
        handleAction({
          type: APP_FORM_ACTIONS.SET_FORM_DATA,
          payload: appformData,
        });
        // when success navigate add form view page
        naviagate(PATHS.APP_FORMS.replace(':id', appformData?.project_id).concat('form_id=', appformData?.id));
      },
    });
  };

  return (
    <CreateAppFormContent>
      <Text>Create Application Form</Text>
      <Form layout="vertical" onFinish={handleFinish} disabled={!nodeTypeId} style={{ width: '100%' }}>
        <Form.Item label="Name" name="name" rules={[{ required: true, min: 3, max: 80 }]}>
          <Input placeholder="Form name" />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[{ required: true, max: 500 }]}>
          <TextArea placeholder="Form Description" />
        </Form.Item>
        <Form.Item label="Application Form Image" name="file">
          <Upload
            fileList={fileList}
            onChange={handleFileChange}
            beforeUpload={beforeUpload}
            multiple={false}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form>
    </CreateAppFormContent>
  );
};
