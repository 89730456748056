import { useMutation } from '@tanstack/react-query';
import client from 'api/client';
import { RequestTypes } from 'api/types';
import { URL_DELETE_SHARE_VISUALIZATION_BY_ID } from './constant';

export const useDeleteShareVisualization = () => {
  return useMutation({
    mutationFn: (id: string) => {
      return client[RequestTypes.Delete](URL_DELETE_SHARE_VISUALIZATION_BY_ID.replace(':id', id));
    },
  });
};
