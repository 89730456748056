import { Flex, Space } from 'antd';
import ReactQuill from 'react-quill';
import { ProjectTypePropertyReturnData } from 'api/types';
import { SecondaryText, Text } from 'components/typography';
import { COLORS, VALIDATE_MESSAGES } from 'helpers/constants';
import { FormItem } from '../form-item';
import 'react-quill/dist/quill.snow.css';
import { useState } from 'react';
import styled from 'styled-components';

type Props = {
  data?: ProjectTypePropertyReturnData;
  name?: string;
};

export const ToolParamsForm = styled(Flex)`
  & .table-editor .ql-container.ql-snow {
    min-height: 50px;
  }
`;

export const RichTextType = ({ data, name }: Props) => {
  const label = (
    <Space>
      <Text color={COLORS.PRIMARY.BLUE}>{`${data?.display_name === undefined ? name : data?.display_name}`}</Text>
      {' / '}
      <SecondaryText color={COLORS.PRIMARY.GRAY}>{`(${
        data?.ref_property_type_id === undefined ? data?.edge_type_property_type : data?.ref_property_type_id
      })`}</SecondaryText>
    </Space>
  );

  const [value, setValue] = useState('');

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <ToolParamsForm style={{ textAlign: 'left' }}>
      <FormItem
        key={data?.id}
        name={[data?.name ?? name, 0]}
        label={label}
        rules={[{ required: data?.required_type, message: VALIDATE_MESSAGES.required }]}
      >
        <ReactQuill
          modules={{
            toolbar: [
              [{ font: [] }],
              ['bold', 'italic', 'underline'],
              [{ size: [] }],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
            ],
          }}
          theme="snow"
          value={value ?? (data?.data?.[0] as string)}
          onChange={handleChange}
          className="table-editor"
        />
      </FormItem>
    </ToolParamsForm>
  );
};
