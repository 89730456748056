export const GET_LEFT_BAR_FILTERS_LISTS = 'save-graphs/filter-left-bar/:project_id';
export const GET_PUBLIC_PROJECT_NODE_TYPES_LIST = '/public/projects-node-types/left-bar/:project_id';
export const GET_PROJECT_NODE_TYPES_LIST = '/projects-node-types/left-bar/:project_id';
export const URL_CREATE_FUZZY_MATCH_GRAPH = '/edges/fuzzy-match/create-data';
export const URL_CREATE_FUZZY_MATCH = '/edges/fuzzy-match/get-data';
export const GET_ALL_DATA = '/neo4j/all-data/:project_id';
export const GET_ALL_PUBLIC_DATA = '/public/neo4j/all-data/:project_id';
export const GET_EDGES = '/edges/:id';
export const GET_SEARCH_DATA = '/neo4j/autocomplete/:project_id';
export const GET_SEARCH_PUBLIC_DATA = '/public/neo4j/autocomplete/:project_id';
export const GET_SEARCH_DATA_SELECTED = '/neo4j/type-or-node/:project_id';
export const GET_SEARCH_PUBLIC_DATA_SELECTED = '/public/neo4j/type-or-node/:project_id';
export const GET_SHORTEST_DATA = '/neo4j/shortest-path/:project_id';
export const GET_SHORTEST_PUBLIC_DATA = '/public/neo4j/shortest-path/:project_id';
export const URL_CREATE_SHARE_VISUALIZATION = '/shared/graphs';
export const URL_DELETE_SHARE_VISUALIZATION_BY_ID = '/shared/graphs/:id';
export const URL_EXTERNAL_SHARE_VISUALIZATION_DATA = '/external/graphs/:id';
export const URL_EXTERNAL_LEFT_BAR_FILTERS = '/external/graphs/filter-left-bar/:id';
