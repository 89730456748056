import React, { useState } from 'react';
import { Button, Flex, Form, message, Tooltip, Upload, UploadFile } from 'antd';
import { Text } from 'components/typography';
import { InfoCircleOutlined, PaperClipOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';
import { COLORS, VALIDATE_MESSAGES } from 'helpers/constants';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background: linear-gradient(91.78deg, rgba(255, 255, 255, 0.64) 6.81%, rgba(255, 255, 255, 0.16) 100%);
  border: 1px solid ${COLORS.PRIMARY.GRAY};
  border-radius: 4px;
  text-align: left;
`;

const StyledUpload = styled(Upload)`
  .ant-upload {
    display: block;
  }
`;

const fileTypes = ['.jpg', '.jpeg', '.png', '.svg'];
const allowedTypes = fileTypes.join(',');
const maxSize = 10; // in MB

export const ImageType: React.FC<AnyObject> = ({ id, question, hover, required }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChangeFile = ({ fileList }: { fileList: UploadFile[] }) => {
    if (fileList.length > 1) {
      setFileList([fileList[fileList.length - 1]]);
    } else {
      setFileList(fileList);
    }
  };

  const validateFile = async (_: unknown, value: UploadFile[]) => {
    if (required && fileList.length === 0) {
      return Promise.reject(new Error(VALIDATE_MESSAGES.required));
    }
    return Promise.resolve();
  };

  const label = (
    <Flex gap={16} align="center">
      <Text>{question}</Text>
      {hover && (
        <Tooltip title={hover}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Flex>
  );

  return (
    <Form.Item label={label} name={id} rules={[{ validator: validateFile }]}>
      <StyledUpload
        maxCount={1}
        fileList={fileList}
        beforeUpload={(file) => {
          const isFileTypeAllowed = fileTypes.some((type) => file.name.toLowerCase().endsWith(type));
          const isFileSizeAllowed = file.size / 1024 / 1024 < maxSize;
          if (!isFileTypeAllowed) {
            message.error(`allowed only the following file types: ${allowedTypes}.`);
          }
          if (!isFileSizeAllowed) {
            message.error(`Not allowed files more than ${maxSize}MB`);
          }
          return false;
        }}
        onChange={handleChangeFile}
      >
        <StyledButton block icon={<PaperClipOutlined />} style={{ justifyContent: 'start' }}>
          Upload File
        </StyledButton>
      </StyledUpload>
    </Form.Item>
  );
};
