import { useDeleteAllDataChecked } from 'api/all-data/use-delete-all-data-checked';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { VerticalSpace } from 'components/space/vertical-space';
import { Text } from 'components/typography';
import { useState } from 'react';
import { Spinning } from 'components/spinning';
import { MainActionButton } from 'components/actions/main-action-button';
import { DeleteOutlined } from '@ant-design/icons';
import { RightSectionAllDataProps } from 'api/types';

type Props = {
  checkedItems: RightSectionAllDataProps[];
  setCheckedItems: (checkedItems: RightSectionAllDataProps[]) => void;
  onSubmit: VoidFunction;
  checkText?: string;
};

export const DeleteAllDataModal = ({ checkedItems, setCheckedItems, onSubmit, checkText }: Props) => {
  const [isDeleteStart, setDeleteStart] = useState(false);
  const { mutate, isLoading } = useDeleteAllDataChecked(
    checkedItems.map((item) => item.id),
    {
      onSuccess: () => {
        setCheckedItems([]);
        onSubmit();
      },
    },
    checkText
  );

  const handleDelete = async () => {
    await mutate();
    setDeleteStart(false);
    setCheckedItems([]);
    onSubmit();
  };

  return (
    <>
      {isLoading && <Spinning />}
      <Modal
        title={<Text style={{ textAlign: 'center' }}>Are you sure you wish to permanently remove this data?</Text>}
        open={isDeleteStart}
        footer={false}
        closable={false}
        className="project-modal"
      >
        <VerticalSpace>
          <Button block onClick={handleDelete} type="primary">
            Delete
          </Button>
          <Button
            block
            type="default"
            onClick={() => {
              setDeleteStart(false);
            }}
          >
            Cancel
          </Button>
        </VerticalSpace>
      </Modal>
      {!!checkedItems.length && (
        <MainActionButton
          helpText="Delete"
          onClick={() => setDeleteStart(true)}
          icon={<DeleteOutlined />}
          disabled={isDeleteStart}
        />
      )}
    </>
  );
};
