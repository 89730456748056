import React from 'react';
import { ReactComponent as ShareSvg } from '../../icons/share-visualization.svg';
import { useVisualizationHistory } from 'context/visualization-history';
import { ACTIONS } from 'context/visualization-history/reducer';

export const ShareVisualization: React.FC = () => {
  const { handleAction } = useVisualizationHistory();
  return (
    <>
      <ShareSvg onClick={() => handleAction({ type: ACTIONS.OPEN_SHARE_DRAWER, payload: true })} />
    </>
  );
};
