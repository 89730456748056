import styled from 'styled-components';
import dotsImage from 'components/icons/dots.svg';

export const Wrapper = styled.div`
  width: 100%;
  display: block;
  z-index: 0;
  canvas {
    background: url(${dotsImage});
  }
`;
