import { Select, Flex } from 'antd';
import { useGetAllNodeTypes } from 'api/node/use-get-node-types';
import { CustomBadge } from 'components/analytics/styles';
import { Text } from 'components/typography';
import { useAppForms } from 'context/app-forms';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';
import React, { useMemo } from 'react';

export const NodeTypes: React.FC = () => {
  const { handleAction, nodeTypeId } = useAppForms();

  const { data, isLoading } = useGetAllNodeTypes();

  const onSelect = (nodeTypeId: string) => {
    handleAction({
      type: APP_FORM_ACTIONS.SELECT_NODE_TYPE,
      payload: nodeTypeId,
    });
  };

  const onClear = () => {
    handleAction({
      type: APP_FORM_ACTIONS.SELECT_NODE_TYPE,
      payload: '',
    });
  };

  const options = useMemo(
    () =>
      data?.map((type) => ({
        label: (
          <Flex gap={16}>
            <CustomBadge color={type.color} />
            {type.name}
          </Flex>
        ),
        value: type.id,
      })) || [],
    [data]
  );

  return (
    <>
      <Text style={{ fontSize: 18 }}>Node Types</Text>
      <Select
        style={{ width: '100%' }}
        placeholder="Select Node Type"
        value={nodeTypeId}
        onSelect={onSelect}
        options={options}
        onClear={onClear}
        allowClear
        loading={isLoading}
      />
    </>
  );
};
