import React from 'react';
import { Flex, Form, Tooltip } from 'antd';
import { Text } from 'components/typography';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { VALIDATE_MESSAGES } from 'helpers/constants';

export const RichTextType: React.FC<AnyObject> = ({ id, question, hover, required }) => {
  const label = (
    <Flex gap={16} align="center">
      <Text>{question}</Text>
      {hover && (
        <Tooltip title={hover}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Flex>
  );

  return (
    <Form.Item label={label} name={id} rules={[{ required, message: VALIDATE_MESSAGES.required }]}>
      <ReactQuill
        modules={{
          toolbar: [
            [{ font: [] }],
            ['bold', 'italic', 'underline'],
            [{ size: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ],
        }}
        theme="snow"
      />
    </Form.Item>
  );
};
