import { StyledMainWrapper } from './styles';
import { QueriesButton } from '../../../components/button/queries-button';
import { TQueriesProps } from '../type';

export const Queries = ({ activeQuerisID, setActiveQuerisID, queryCollapsed, setQueryCollapsed }: TQueriesProps) => {
  return (
    <>
      <StyledMainWrapper>
        <QueriesButton
          setActiveQuerisID={setActiveQuerisID}
          activeQuerisID={activeQuerisID}
          isQueries={true}
          queryCollapsed={queryCollapsed}
          setQueryCollapsed={setQueryCollapsed}
        />
      </StyledMainWrapper>
    </>
  );
};
