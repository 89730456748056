import { MenuProps } from 'antd';
import { MenuText } from 'components/typography';
import { ReactComponent as Home } from '../icons/home.svg';
import { ReactComponent as Public } from '../icons/public.svg';
import { ReactComponent as Shared } from '../icons/shared.svg';
import { ReactComponent as TemplateIcon } from '../icons/template.svg';
import { ReactComponent as GroupsIcon } from '../icons/groups.svg';
import { ReactComponent as AppFormsIcon } from '../icons/app-forms.svg';
import { HomeSidebarMenuTitles, PATHS } from 'helpers/constants';

/**
 *  Represent Admin menu list
 *
 *  @see adminMenu
 *  @example
 *   const menuItem = {
 *   icon: <Icon />,
 *   label: <MenuText>[name]</MenuText>,
 *   key: PATHS.[name],
 *  };
 */

export const adminMenu: MenuProps['items'] = [
  {
    icon: <Home />,
    label: <MenuText>Dashboard</MenuText>,
    key: PATHS.ADMIN,
  },
  {
    icon: <Public />,
    label: <MenuText>User Management</MenuText>,
    key: PATHS.ADMIN_MANAGEMENT,
  },
];

/**
 *  Represent Project Home menu list
 *
 *  @see projectHomeMenu
 *  @example
 *   const menuItem = {
 *   icon: <Icon />,
 *   label: <MenuText>[name]</MenuText>,
 *   key: PATHS.[name],
 *  };
 */

export const projectHomeMenu: MenuProps['items'] = [
  {
    icon: <Home />,
    label: <MenuText>{HomeSidebarMenuTitles.HOME}</MenuText>,
    key: PATHS.PROJECTS,
  },
  {
    icon: <Public />,
    label: <MenuText>{HomeSidebarMenuTitles.PUBLIC}</MenuText>,
    key: PATHS.PUBLIC,
  },
  {
    icon: <Shared />,
    label: <MenuText>{HomeSidebarMenuTitles.SHARED}</MenuText>,
    key: PATHS.SHARED,
  },
  {
    icon: <TemplateIcon />,
    label: <MenuText>{HomeSidebarMenuTitles.TEMPLATE}</MenuText>,
    key: PATHS.TEMPLATE,
  },
  {
    icon: <GroupsIcon />,
    label: <MenuText>{HomeSidebarMenuTitles.GROUPS}</MenuText>,
    key: PATHS.GROUPS,
  },
  {
    icon: <AppFormsIcon />,
    label: <MenuText>{HomeSidebarMenuTitles.APP_FORMS}</MenuText>,
    key: PATHS.APP_FORMS_LISTS,
  },
];
