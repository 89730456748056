/**
 * Represent Share Button type
 *
 * @see Update
 * @see Save_AS
 */

export enum ShareButtonType {
  UPDATE = 'Update',
  SAVE_AS = 'Save as',
}

/**
 * Represent Share Drawer texts
 *
 * @see TITLE
 * @see LinkTitle
 * @see INFO
 */

export enum ShareDrawerText {
  Title = 'Share Visualization',
  LinkTitle = 'Public View Link',
  Info = 'Send a public view link to allow anyone to access a view-only version of this design, no sign-in required.',
  FormStatus = 'Form Status',
  FormStatusText = 'Enable, disable, or conditionally enable your form',
}

/**
 * Represent Share Data status
 *
 * @see ENABLE
 * @see DISABLE
 * @see DISABLE_SPECIFIC
 */

export enum ShareDataStatus {
  ENABLE = 'enable',
  DISABLE = 'disable',
  DISABLE_SPECIFIC = 'disable_specific',
}

/**
 * Represent Share select option values
 *
 * @see ENABLE
 * @see DISABLE
 * @see DISABLE_SPECIFIC
 */

export enum ShareOptionsValue {
  ENABLE = 'Enable',
  DISABLE = 'Disable',
  DISABLE_SPECIFIC = 'Disable on specific date',
}

/**
 * Represent Share select option structure
 *
 * @see ShareOptions
 * @example {
 *  label: view name
 *  value: value
 * }
 */

export const ShareOptions = [
  {
    value: ShareDataStatus.ENABLE,
    label: ShareOptionsValue.ENABLE,
  },
  {
    value: ShareDataStatus.DISABLE,
    label: ShareOptionsValue.DISABLE,
  },
  {
    value: ShareDataStatus.DISABLE_SPECIFIC,
    label: ShareOptionsValue.DISABLE_SPECIFIC,
  },
];
