import React, { memo, useEffect, useRef, useState } from 'react';
import { Graph } from '@antv/g6';

import { graphRender } from 'components/layouts/components/visualisation/helpers/utils';
import { formattedData } from 'components/layouts/components/visualisation/helpers/format-node';
import { GraphData } from 'components/layouts/components/visualisation/types';
import { initData } from 'components/layouts/components/visualisation/container/initial/nodes';
import { useGraph } from 'components/layouts/components/visualisation/wrapper';
import { Spinning } from 'components/spinning';
import { LeftSection } from 'pages/project-visualisation/left-section';
import { ToolbarVisualization } from 'components/tool-bar';
import { ShareNodeView } from '../drawers/nodes/view-share-node';
import { GraphG6Config } from './config';
import { Settings } from '../settings';
import { GraphInfo } from '../info';
import { useIsPublicPage } from 'hooks/use-is-public-page';
import { useGetExternalSharedData } from 'api/visualisation/use-get-external-shared-data';
import { Wrapper } from './styles';

type GraphRef = React.MutableRefObject<HTMLDivElement | null>;

export const ShareGraph: React.FC = memo(() => {
  const [isGraphReady, setIsGraphReady] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [nodeId, setNodeId] = useState<string | undefined>(undefined);
  const [queryCollapsed, setQueryCollapsed] = useState(false);

  const { setGraph, setGraphInfo } = useGraph();
  const isPublicPage = useIsPublicPage();

  const graphRef: GraphRef = useRef(null);
  const graphInstance = useRef<Graph | null>(null);

  const { nodes, edges, relationsCounts, count, savedGraph, isLoading } = useGetExternalSharedData(true);

  const onHandleRemoveNodeId = () => setNodeId(undefined);

  useEffect(() => {
    if (setGraphInfo) {
      if (collapsed) {
        setGraphInfo({ collapsedWidth: queryCollapsed ? 780 : 480 });
      } else {
        setGraphInfo({ collapsedWidth: 0 });
      }
    }
  }, [collapsed, queryCollapsed, setGraphInfo]);

  useEffect(() => {
    if (nodes && edges) {
      if (graphRef.current && !graphInstance.current) {
        const graph = GraphG6Config({
          container: graphRef.current,
          width: window.innerWidth,
          height: window.innerHeight,
          setNodeId,
        }) as Graph;

        graphInstance.current = graph;
        setIsGraphReady(true);
      }

      if (graphInstance.current) {
        const results = formattedData(nodes, edges, relationsCounts, savedGraph) as GraphData;
        setGraph(graphInstance.current);
        setGraphInfo({
          nodeCount: nodes?.length,
          nodeCountAPI: count,
        });

        initData(graphInstance.current, results);
        graphRender(graphInstance.current);
      }
    }

    return () => {
      if (graphInstance.current) {
        graphInstance.current.destroy();
        graphInstance.current = null;
        setIsGraphReady(false);
      }
    };
  }, [count, edges, nodes, relationsCounts, savedGraph, setGraph, setGraphInfo]);

  if (isLoading) {
    return <Spinning spinning={true} />;
  }

  return (
    <>
      <Wrapper ref={graphRef}></Wrapper>
      {isGraphReady && graphInstance.current && <Settings graph={graphInstance.current} isShared={true} />}
      <GraphInfo />
      <ToolbarVisualization />
      <ShareNodeView node_id={nodeId} onClose={onHandleRemoveNodeId} />
      <LeftSection
        isShared={true}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        queryCollapsed={queryCollapsed}
        setQueryCollapsed={setQueryCollapsed}
        isPublicProject={!isPublicPage}
      />
    </>
  );
});
