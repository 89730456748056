import React, { useState } from 'react';
import { Form, Tooltip, Select, Flex } from 'antd';
import { VALIDATE_MESSAGES } from 'helpers/constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Text } from 'components/typography';
import { useGetTargetNodesById } from 'api/app-forms/use-get-target-nodes-by-id';
import { AnyObject } from 'antd/es/_util/type';

export const ConnectionType: React.FC<AnyObject> = ({ id, question, required, hover, placeholder }) => {
  const [search, setIsSearch] = useState<string | undefined>(undefined);
  const [value, setIsValue] = useState('');

  const { data } = useGetTargetNodesById(
    { id, search },
    {
      enabled: (search && search.length > 3) || !!id,
    }
  );

  const handleSelect = (value: string) => {
    setIsValue(value);
  };

  const handleSearch = (value: string) => {
    if (value.length > 2) {
      setIsSearch(value);
    } else {
      setIsSearch(undefined);
    }
  };

  const options = data?.rows?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const label = (
    <Flex gap={16} align="center">
      <Text>{question}</Text>
      {hover && (
        <Tooltip title={hover}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Flex>
  );

  return (
    <Form.Item label={label} name={`${id}_edge`} rules={[{ required, message: VALIDATE_MESSAGES.required }]}>
      <Select
        showSearch
        allowClear
        placeholder={placeholder}
        value={value}
        onSelect={handleSelect}
        onSearch={handleSearch}
        filterOption={false}
        options={options}
        notFoundContent={search && search.length > 2 ? 'No results found' : null}
      />
    </Form.Item>
  );
};
