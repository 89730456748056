/**
 * @namespace URLs_APP_FORMS
 * @description This file defines all application form URLs
 *
 * @constant {string} URL_APP_FORMS_LISTS - URL get all app forms
 * @constant {string} URL_APP_FORMS_BY_PROJECT_ID - URL for accessing a specific app form by project
 * @constant {string} URL_APP_FORMS_BY_ID - URL for accessing a specific app form by project and form ID.
 * @constant {string} URL_APP_FORMS_PROPERTY_BY_ID - URL for accessing a specific property within an app form.
 * @constant {string} URL_APP_FORMS_EDGE_BY_ID - URL for accessing a specific edge within an app form.
 * @constant {string} URL_GET_LEFT_BAR_DATA - URL for accessing a specific get all data left bar
 * @constant {string} URL_POST_EXTERNAL_FORM - URL to form submit create node
 * @constant {string} URL_GET_TARGET_NODES_BY_ID - URL to form search target nodes by edge_type_id
 */

export const URL_APP_FORMS_LISTS = '/app-forms';
export const URL_APP_FORMS_BY_PROJECT_ID = '/app-forms/:project_id';
export const URL_APP_FORMS_BY_ID = '/app-forms/:project_id/:id';
export const URL_APP_FORMS_PROPERTY_BY_ID = '/app-forms/property/:project_id/:id';
export const URL_APP_FORMS_EDGE_BY_ID = '/app-forms/edge/:project_id/:id';
export const URL_GET_LEFT_BAR_DATA = '/app-forms/left-bar/:project_id/:id';
export const URL_POST_EXTERNAL_FORM = '/external/app-forms/:id';
export const URL_GET_TARGET_NODES_BY_ID = '/external/app-forms/target-nodes/:id';
