import styled from 'styled-components';
import { Input as CustomInput } from 'antd';
import { COLORS } from 'helpers/constants';

/**
 * @namespace Input
 *
 * @description This global Search style component
 *
 */

export const Input = styled(CustomInput)`
  border: 1px solid ${COLORS.MAIN_GRAY_SILVER};
  border-radius: 4px;
  & .ant-input-group-addon {
    border: none;
    padding: 2px 5px;
  }
  & .ant-input {
    border: none;
    padding: 2px;
    font-size: 18px;
  }
`;
