import { useMutation } from '@tanstack/react-query';
import client from 'api/client';
import { useParams } from 'react-router-dom';
import { IFirstElementBulkLocation } from 'types/menu-node';

const USE_BULK_NODE = '/nodes/bulk-update/:project_id';
const USE_BULK_CONNECTION = '/edges/bulk-update/:project_id';
interface IData {
  project_type_property_id?: string;
  edge_type_property_id?: string;
  value?: Array<unknown> | IFirstElementBulkLocation | string;
}
export const useBulkUpdateNode = ({ name }: { name: string }) => {
  const { id } = useParams();
  const URL = name ? USE_BULK_CONNECTION : USE_BULK_NODE;

  const mutation = useMutation({
    mutationFn: (data: IData) => {
      return client.put(URL.replace(':project_id', id ?? ''), data);
    },
  });
  return mutation;
};
