import { TInitialState } from './types';

/**
 * @description Represents the initial state for history visualization.
 *
 * @typedef {Object} TInitialState
 * @property {string | undefined} name - The name of the visualization (optional).
 * @property {boolean} openSaveModal - Indicates whether the save modal is open.
 * @property {string | undefined} visualizationId - The ID of the visualization (optional).
 * @property {any | undefined} hoverItem - The currently hovered item (optional).
 * @property {boolean} reset - Indicates whether the visualization should reset.
 * @property {AnyObject | undefined} queriesStyle - The style configuration for queries (optional).
 * @property {string} userId - The ID of the user associated with the visualization.
 */

export const initialState: TInitialState = {
  name: undefined,
  openSaveModal: false,
  visualizationId: undefined,
  hoverItem: undefined,
  reset: false,
  queriesStyle: undefined,
  userId: '',
  filterCheckedList: [],
  openShareDrawer: false,
  sharedData: null,
};
