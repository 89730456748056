import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { URL_APP_FORMS_BY_ID } from './constant';
import { RequestTypes } from 'api/types';
import client from 'api/client';
import { TUseGetFormId } from './types';

/**
 * Custom hook to fetch the app form by id.
 *
 * @param none
 *
 * @returns An object containing the fetched data and loading state.
 *
 */

export const useGetFormsById: TUseGetFormId = () => {
  const { id } = useParams();

  const searchParams = new URLSearchParams(window.location.search);
  const form_id = searchParams.get('form_id') || '';
  const API_URL = URL_APP_FORMS_BY_ID.replace(':project_id', id || '').replace(':id', form_id);

  const results = useQuery({
    queryKey: [API_URL],
    queryFn: () => client[RequestTypes.GET](API_URL),
    select: (data) => data.data,
    enabled: !!form_id.length,
    retry: 1,
  });

  const { data, isLoading, isFetched, isError } = results;

  return {
    data,
    isLoading,
    isFetched,
    isError,
  };
};
