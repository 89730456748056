/**
 * @constant {Array<Object>} columns
 * @description Configuration for table columns used in the FormLists component.
 *
 * @property {string} title - The display name of the column header.
 * @property {string} name - A unique identifier for the column.
 * @property {string} dataIndex - The key used to access the corresponding data field in the data source.
 *
 */

import { COLORS } from 'helpers/constants';

export const columns = [
  {
    title: 'Project',
    name: 'project_name',
    dataIndex: 'project_name',
  },
  {
    title: 'Form Title',
    name: 'title',
    dataIndex: 'title',
  },
  {
    title: 'Status',
    name: 'status',
    dataIndex: 'status',
  },
  {
    title: 'Created date',
    name: 'created_at',
    dataIndex: 'created_at',
  },
  {
    title: 'Applicants',
    name: 'applicants',
    dataIndex: 'applicants',
  },
  {
    title: 'Link',
    name: 'link',
    dataIndex: 'link',
  },
  {
    name: 'action',
    dataIndex: 'action',
  },
];

export const styles = {
  link: {
    color: COLORS.BLUE_10,
    fontSize: 16,
    fontWeight: 700,
  },
};
