import styled from 'styled-components';
import { Flex } from 'antd';
import { COLORS } from 'helpers/constants';

export const LeftSection = styled(Flex)`
  width: 420px;
  height: calc(100vh - 150px);
  background-color: ${COLORS.MAIN_GRAY};
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  box-shadow: 10px 10px 9px ${COLORS.MAIN_GRAY_SILVER};
`;

export const CreateAppFormContent = styled(Flex)`
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
  background: ${COLORS.PRIMARY.WHITE};
  margin: 50px auto;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 10px 10px 9px ${COLORS.MAIN_GRAY_SILVER};
`;

export const AppFormItem = styled(Flex)<{ isTouched?: boolean; isSaved?: boolean }>`
  width: 100%;
  height: auto;
  background-color: ${COLORS.PRIMARY.WHITE};
  padding: 16px;
  margin: 4px 0;
  border-radius: 8px;
  box-shadow: 10px 10px 9px ${COLORS.PRIMARY.SILVER_LIGHT};
  border-left: 5px solid
    ${(props) =>
      props?.isSaved
        ? props?.isTouched
          ? COLORS.SECONDARY.YELLOW
          : COLORS.SECONDARY.GREEN
        : COLORS.SECONDARY.MAGENTA};
`;

export const FormContent = styled(Flex)`
  padding: 16px;
  max-height: calc(100vh - 400px);
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const FormFooter = styled(Flex)`
  width: 100%;
  height: 80px;
  flex: 33% 33% 33%;
  justify-content: space-around;
  align-items: center;
  gap: 22px;
  padding: 0 16px;
  background: ${COLORS.MAIN_GRAY};
  box-shadow: 0px -10px 10px ${COLORS.MAIN_GRAY_SILVER};
  position: absolute;
  bottom: 0;
`;

export const LeftBarContent = styled(Flex)`
  max-height: 300px;
  flex-direction: column;
  overflow-y: scroll;
  box-shadow: 3px 3px 10px ${COLORS.MAIN_GRAY_SILVER};
  border-radius: 4px;
  padding: 8px;
  border-radius: 8px;
`;
