import { Form } from 'antd';
import { FormInput } from 'components/input';
import { FormItem } from 'components/form/form-item';
import { PropertyTypes } from 'components/form/property/types';
import { Rule } from 'antd/es/form';

export const AddSchemaTypePropertyTitle = () => {
  const dataType = Form.useWatch('ref_property_type_id', { preserve: true });

  const isConnection = dataType === PropertyTypes.Connection;

  return (
    <FormItem
      name="name"
      label={isConnection ? 'Connection name' : 'Property name'}
      rules={[
        {
          required: true,
          message: isConnection ? 'Connection name is required' : 'Property name is required',
        },
        {
          min: dataType === PropertyTypes.Connection ? 2 : 3,
          message: `The minimum length for this field is ${dataType === PropertyTypes.Connection ? 2 : 3} characters`,
        },
        { max: 30, message: 'The maximum length for this field is 30 characters' },
        {
          validator: async (_: Rule, value: string | undefined) => {
            if (value !== undefined && value !== '') {
              const regex = /^[a-zA-Z][a-zA-Z0-9_ ]*$/;
              if (!regex.test(value)) {
                return Promise.reject(
                  'Name must start with a letter and contain only letters, numbers, underscores, and spaces'
                );
              }
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <FormInput placeholder="Property name" />
    </FormItem>
  );
};
