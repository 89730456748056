import React, { memo, useCallback } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Flex, Tooltip } from 'antd';
import { Text } from 'components/typography';
import { COLORS } from 'helpers/constants';
import { IFormItemHeaderProps } from '../type';

export const FormItemHeader: React.FC<IFormItemHeaderProps> = memo(({ name, id, isTouched }) => {
  const getTitleInfo = useCallback(() => {
    return id
      ? isTouched
        ? `The ${name} field is changed. Click to Update`
        : `The ${name} is saved`
      : `The ${name} is not saved. Click to save`;
  }, [id, isTouched, name]);

  return (
    <Flex gap={16}>
      <Text style={{ fontSize: 18, fontWeight: 700 }}>{name}</Text>
      <Tooltip placement="right" title={getTitleInfo}>
        <InfoCircleOutlined
          style={{
            color: id ? (isTouched ? COLORS.SECONDARY.YELLOW : COLORS.SECONDARY.GREEN) : COLORS.SECONDARY.MAGENTA,
            cursor: id ? 'default' : 'pointer',
          }}
        />
      </Tooltip>
    </Flex>
  );
});
