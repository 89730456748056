import React from 'react';
import { FormView } from './components/form-view';
import { useGetExternalAppForm } from 'api/app-forms/use-get-external-app-form';
import { Flex } from 'antd';
import { Spinning } from 'components/spinning';
import { ErrorNotFound } from 'pages/error/errorNotFound';

export const ExternalAppForm: React.FC = () => {
  const { data, isLoading, isError } = useGetExternalAppForm();

  if (isLoading) return <Spinning />;

  if (isError) return <ErrorNotFound />;

  return (
    <Flex style={{ width: '50%', margin: '0px auto' }} justify="center" align="center">
      <FormView appFormId={data?.id} data={data} url={data?.background_image} isSubmit={true} />
    </Flex>
  );
};
