import React, { memo } from 'react';
import { Drawer, Flex, Skeleton } from 'antd';
import { COLORS } from 'helpers/constants';

import { useGetExternalNode } from 'api/node/use-get-share-node';
import { VIewNode } from 'pages/data-sheet/components/table-section/node/view-node';
import { Text } from 'components/typography';

export const ShareNodeView: React.FC<{ node_id?: string; onClose: () => void }> = memo(({ node_id, onClose }) => {
  const { data, isLoading } = useGetExternalNode(node_id || '');

  const renderTitle = (color: string) => (
    <Flex style={{ borderTop: `8px solid ${color}` }}>
      <Text style={{ fontSize: 18, padding: '24px' }}>{data?.name}</Text>
    </Flex>
  );

  return (
    <Drawer
      title={renderTitle(data?.nodeType?.color)}
      closeIcon={null}
      onClose={onClose}
      width={480}
      headerStyle={{
        padding: 0,
      }}
      drawerStyle={{
        background: COLORS.MAIN_GRAY,
        boxShadow: '10px 10px 10px 0px rgba(111, 111, 111, 0.10) inset',
      }}
      getContainer={false}
      open={!!node_id}
    >
      <>
        <Skeleton loading={isLoading} />
        <VIewNode data={data} isShared={true} />
      </>
    </Drawer>
  );
});
