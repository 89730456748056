import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormFooter } from '../style';
import { DatePicker, Flex, Select } from 'antd';
import { PATHS, StatusActionType } from 'helpers/constants';
import { useAppForms } from 'context/app-forms';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { EyeOutlined } from '@ant-design/icons';
import { CopyLink } from 'components/actions/copy-link';
import { useUpdateAppForm } from 'api/app-forms/use-update-app-form';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';
import { IFooterParams } from '../type';

const optionsText = {
  [StatusActionType.ENABLE]: 'Enable',
  [StatusActionType.DISABLE]: 'Disable',
  [StatusActionType.DISABLE_SPECIFIC]: 'Disable on a specific date',
};

export const Footer: React.FC = () => {
  const { form, handleAction } = useAppForms();
  const { mutate: updatedFn } = useUpdateAppForm();

  const [isSpecific, setIsSpecific] = useState(false);

  const expiredDate = useMemo(
    () => (form?.expired_date ? dayjs(form.expired_date) : dayjs().add(1, 'day')),
    [form.expired_date]
  );

  const options = Object.values(StatusActionType).map((value) => ({
    label: optionsText[value],
    value,
  }));

  const disabledDate: RangePickerProps['disabledDate'] = (current) => current && current < dayjs().endOf('day');

  const handleStatusChange = useCallback(
    (value: string) => {
      const isSpecificStatus = value === StatusActionType.DISABLE_SPECIFIC;
      setIsSpecific(isSpecificStatus);

      const params: IFooterParams = {
        status: value,
        expired_date: isSpecificStatus ? expiredDate?.format('MM/DD/YYYY') : undefined,
      };

      handleAction({
        type: APP_FORM_ACTIONS.CHANGE_APPLICATION_FORM_STATUS,
        payload: value,
      });

      updatedFn({ formId: form?.id, params });
    },
    [expiredDate, handleAction, updatedFn, form?.id]
  );

  const handleDateChange = useCallback(
    (date: Dayjs | null) => {
      updatedFn({
        formId: form?.id,
        params: {
          status: StatusActionType.DISABLE_SPECIFIC,
          expired_date: date ? dayjs(date).format('MM/DD/YYYY') : undefined,
        },
      });
    },
    [form?.id, updatedFn]
  );

  const handleViewOpen = useCallback(() => {
    handleAction({ type: APP_FORM_ACTIONS.SHOW_VIEW, payload: true });
  }, [handleAction]);

  useEffect(() => {
    if (form) {
      setIsSpecific(form?.status === StatusActionType.DISABLE_SPECIFIC);
    }
  }, [form]);

  return (
    <FormFooter>
      <EyeOutlined onClick={handleViewOpen} />
      <Flex gap={16} justify="center" style={{ width: '50%' }}>
        <Select
          style={{ minWidth: 300 }}
          onChange={handleStatusChange}
          size="large"
          options={options}
          value={form?.status}
        />
        {isSpecific && (
          <DatePicker size="large" onChange={handleDateChange} defaultValue={expiredDate} disabledDate={disabledDate} />
        )}
      </Flex>
      <CopyLink path={PATHS.APP_FORM_EXTERNAL.replace(':id', form.id)} />
    </FormFooter>
  );
};
