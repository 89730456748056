import client from 'api/client';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { RequestTypes } from 'api/types';
import { URL_GET_EXTERNAL_SHARE_NODE_VIEW } from './constants';
import { NodeDataResponse } from 'types/node';

export const useGetExternalNode = (node_id: string) => {
  const { id } = useParams();
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const BASE_URL = URL_GET_EXTERNAL_SHARE_NODE_VIEW.replace(':id', id || '');
  const results = useQuery({
    queryKey: [BASE_URL, node_id],
    queryFn: async () => {
      const response = await client[RequestTypes.GET](BASE_URL, {
        params: {
          token,
          node_id,
        },
      });
      return response.data;
    },
    enabled: Boolean(node_id),
  });

  const { data, isLoading } = results;

  return {
    data: data as NodeDataResponse,
    isLoading,
  };
};
