import { createContext, useCallback, useContext, useMemo, useReducer, ReactElement } from 'react';
import { TVisualizationHistoryActions, TVisualizationHistoryContext } from './types';
import { reducer } from './reducer';
import { initialState } from './initialState';
import { useAuth } from 'context/auth-context';

const VisualizationHistoryContext = createContext<TVisualizationHistoryContext | undefined>(undefined);

export const VisualizationHistoryProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();

  const handleAction = useCallback((data: TVisualizationHistoryActions) => {
    dispatch(data);
  }, []);

  const userHasPermission = useCallback(() => {
    return state.userId === user?.id;
  }, [state.userId, user?.id]);

  const context = useMemo(
    () => ({ ...state, handleAction, userHasPermission }),
    [state, handleAction, userHasPermission]
  );

  return <VisualizationHistoryContext.Provider value={context}>{children}</VisualizationHistoryContext.Provider>;
};

export const useVisualizationHistory = () => {
  const context = useContext(VisualizationHistoryContext);
  if (context === undefined) {
    throw new Error('useVisualizationHistory must be used within an useVisulizationHistory');
  }
  return context;
};
