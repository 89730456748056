import client from 'api/client';
import { GetNeo4jData } from 'api/visualisation/use-get-data';
import {
  AddEdges,
  CalcExpandList,
  Edge,
  ExpandList,
  ExpandListData,
  GraphDataExpand,
  GroupAndCountResult,
  GroupedData,
} from '../types';
import { renderTooltipModal } from './tooltip';
import { Graph, IEdge, IGraph, INode, Item, LayoutConfig, NodeConfig } from '@antv/g6';
import { allSvg, inSvg, outSvg } from './svgs';
import { formattedData } from './format-node';
import { PATHS } from 'helpers/constants';
import { initConnector } from '../container/initial/nodes';
import { edgeLabelCfgStyle, layoutConfig, nodeLabelCfgStyle } from './constants';

export const getExpandData = async (id: string, label: string, direction: string) => {
  const projectId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const url = `${process.env.REACT_APP_BASE_URL}${
    location.pathname.startsWith(PATHS.PUBLIC_PREFIX) ? 'public/' : ''
  }neo4j/expand/${projectId}`;

  const params: { params: { id: string; direction?: string; label?: string } } = {
    params: {
      id,
    },
  };

  if (label) {
    params.params.label = label;
    params.params.direction = direction;
  } else {
    params.params.direction = 'all';
  }

  const data: GetNeo4jData = await client.get(url, params);

  return data.data;
};

export const getExpandList = async (id: string) => {
  const expandList: ExpandListData | null = await client.get(
    `${process.env.REACT_APP_BASE_URL}${
      location.pathname.startsWith(PATHS.PUBLIC_PREFIX) ? 'public/' : ''
    }neo4j/expand-list/${location.pathname.substring(location.pathname.lastIndexOf('/') + 1)}`,
    {
      params: { id },
    }
  );

  return expandList?.data.relations;
};

/**
 * Render Context Menu Modal
 * Get expandList from localStorage which is saved from node:mouseenter event
 * @param id
 * @param isNode
 */
export const getMenuContexts = (id: string, isNode: boolean, isEdit: boolean, showShortestPath: boolean) => {
  const nodeContext = `<div class='menu'>
      <span class='focus'>Focus on node</span>
      <span class="main-menu expand">Expand</span>
      <div class='menu submenu-container'>
        <div class='submenu'>
        </div>
      </div>
      ${showShortestPath ? "<span class='shortest-path'>Shortest path</span>" : ''}
      ${isEdit ? "<span class='delete'>Delete</span>" : ''}
    </div>`;

  const canvasContext = `<div class='menu'>
      ${isEdit ? '<span>Create Node</span>' : ''}
      <span class="export">Export/PNG</span>
    </div>`;

  const edgeContext = `<div class='menu'>
      ${isEdit ? "<span class='delete'>Delete</span>" : ''}
    </div>`;

  const comboContext = `<div class='menu'>
      ${isEdit ? "<span class='delete'>Delete</span>" : ''}
    </div>`;

  return { canvasContext, nodeContext, comboContext, edgeContext };
};

export const addTooltip = (graph: Graph) => {
  const defaultModes = graph.get('modes')?.default;

  if (!(defaultModes && defaultModes.find((a: { type: string }) => a.type === 'tooltip'))) {
    const el = document.getElementsByClassName('g6-tooltip');

    if (el.length) (el[0] as HTMLElement).remove();

    graph.addBehaviors(
      [
        {
          type: 'tooltip',
          formatText: (model: { [key: string]: unknown }) => {
            return renderTooltipModal(model);
          },
          offset: 10,
        },
      ],
      'default'
    );
  }
};

export const removeTooltip = (graph: Graph) => {
  graph.removeBehaviors('tooltip', 'default');

  const tooltips = document.getElementsByClassName('g6-tooltip');

  if (tooltips.length > 0) {
    const tooltip = tooltips[0] as HTMLElement;

    if (tooltips.length > 1) {
      tooltip.remove();
    } else {
      tooltip.style.display = 'none';
    }
  }
};
/**
 *
 * @param id
 * @param edges
 */
export const updateExpandList = (id: string, edges: IEdge[]) => {
  (async () => {
    const expandList = await getExpandList(id);

    const { result, grandTotal } = calcExpandList(expandList ?? [], edges);

    const allData = `<span id="all"><p>${allSvg}</p> All (${grandTotal})</span>`;

    const list = expandList?.length
      ? `${result
          .map(
            (l) => `
            <div class="row">
            <div class="hidden">${l.name} ${l.direction}</div>
              <p>
                ${l.direction === 'in' ? outSvg : inSvg}
              </p>
              <div class="right-section">
                <p class="name">${l.name}</p>
                <p>(${l.total})</p>
              </div>
            </div>`
          )
          .join(' ')}`
      : '';

    const menuContainer = document.querySelector('.submenu');

    if (menuContainer) {
      menuContainer.innerHTML = `${allData}${list}`;
    }
  })();
};

const calcExpandList: CalcExpandList = (data, visualizedConnections) => {
  const visualizedIds = new Set(visualizedConnections.map((conn) => conn.getID()));

  const notVisualizedData = data.filter((conn) => !visualizedIds.has(conn.id));

  const groupAndCount = (data: ExpandList): GroupAndCountResult => {
    const grouped: { [key: string]: GroupedData } = {};
    data.forEach((item) => {
      const key = `${item.name}-${item.direction}`;
      if (!grouped[key]) {
        grouped[key] = {
          name: item.name,
          project_edge_type_id: item.project_edge_type_id,
          direction: item.direction,
          total: 0,
        };
      }
      grouped[key].total += 1;
    });

    const result = Object.values(grouped).sort(
      (a, b) => a.name.localeCompare(b.name) || a.direction.localeCompare(b.direction)
    );

    const grandTotal = data.length;

    return { result, grandTotal };
  };

  const { result, grandTotal } = groupAndCount(notVisualizedData);

  return { result, grandTotal };
};

/**
 * @description Expand nodes radius calculation
 *
 * @param nodeCount
 * @param minDistance
 */

const calculateBaseRadius = (nodeCount: number, minDistance: number): number => {
  if (nodeCount <= 1) return 0;
  return (minDistance * nodeCount) / (2 * Math.PI);
};

/**
 * @description Expand node position adjustment
 *
 * @param x
 * @param y
 * @param existingNodes
 */

const adjustPosition = (x: number, y: number, existingNodes: NodeConfig[]): { x: number; y: number } => {
  const MIN_DISTANCE = 15;

  let adjustedX = x;
  let adjustedY = y;

  for (let i = 0; i < existingNodes.length; i++) {
    const existingNode = existingNodes[i];
    const dx = adjustedX - (existingNode.x ?? 0);
    const dy = adjustedY - (existingNode.y ?? 0);
    const distance = Math.sqrt(dx * dx + dy * dy);

    if (distance < MIN_DISTANCE) {
      const angle = Math.atan2(dy, dx);
      const offset = MIN_DISTANCE - distance;
      adjustedX += Math.cos(angle) * offset;
      adjustedY += Math.sin(angle) * offset;

      i = -1;
    }
  }

  return { x: adjustedX, y: adjustedY };
};

/**
 * @description Expand by node data
 *
 * @param graph
 * @param item
 * @param nodeId
 * @param label
 * @param direction
 * @param setGraphInfo
 */

export const expandByNodeData = async (
  graph: Graph,
  item: Item,
  nodeId: string,
  label: string,
  direction: string,
  setGraphInfo: (info: { nodeCount?: number | undefined; nodeCountAPI?: number | undefined }) => void
) => {
  let expandData;
  try {
    expandData = await getExpandData(nodeId, label, direction);
  } catch (error) {
    return;
  }

  const graphData = formattedData(expandData.nodes, expandData.edges, expandData.relationsCounts);
  const existingNodes: NodeConfig[] = graph.getNodes().map((node) => node.getModel() as NodeConfig);
  addNodesInNewCombo(graph, graphData, item, existingNodes);

  const edgeMap = new Map();
  graphData.edges.forEach((e) => {
    const source = e.source;
    const target = e.target;

    const edgeKey = [source, target].sort().join('-');
    const overlapCount = edgeMap.get(edgeKey) || 0;
    edgeMap.set(edgeKey, overlapCount + 1);

    const curveOffset = overlapCount * 100;
    graph.addItem('edge', {
      ...e,
      type: 'quadratic',
      curveOffset,
      labelCfg: edgeLabelCfgStyle,
    });
  });

  updateConnector(graph);

  setGraphInfo({
    nodeCount: graph.getNodes().length,
  });
};

/**
 * @description Expand node new combo
 *
 * @param graph
 * @param graphData
 * @param item
 * @param existingNodes
 */

let comboIndex = 0;

const addNodesInNewCombo = (graph: Graph, graphData: GraphDataExpand, item: Item, existingNodes: NodeConfig[]) => {
  const comboId = `combo-${Date.now()}`;

  graph.addItem('combo', {
    id: comboId,
    label: ``,
    style: {
      fill: 'transparent',
      stroke: 'transparent',
    },
  });

  const baseX = comboIndex * 800 + (comboIndex > 0 ? 200 : 0);
  const baseY = 0;

  const expandNodeId = item.getID();
  const expandNode = graph.findById(expandNodeId);

  if (expandNode) {
    const updatedNodeConfig = {
      comboId,
      x: baseX,
      y: baseY,
    };
    graph.updateItem(expandNode, updatedNodeConfig);
  }

  const newNodes = graphData.nodes.filter((n) => n.id !== expandNodeId);
  const baseRadius = calculateBaseRadius(newNodes.length, 80);

  if (newNodes.length === 1) {
    const x = baseX + 150;
    const y = baseY;

    const { x: adjustedX, y: adjustedY } = adjustPosition(x, y, [...existingNodes, ...newNodes]);
    graph.addItem('node', {
      ...newNodes[0],
      comboId,
      labelCfg: nodeLabelCfgStyle,
      x: Math.round(adjustedX),
      y: Math.round(adjustedY),
    });
  } else {
    newNodes.forEach((n, index) => {
      const angleStep = (Math.PI * 2) / newNodes.length;
      const angle = angleStep * index;
      const x = baseX + baseRadius * Math.cos(angle);
      const y = baseY + baseRadius * Math.sin(angle);

      const { x: adjustedX, y: adjustedY } = adjustPosition(x, y, [...existingNodes, ...newNodes]);
      graph.addItem('node', {
        ...n,
        comboId,
        labelCfg: nodeLabelCfgStyle,
        x: Math.round(adjustedX),
        y: Math.round(adjustedY),
      });
    });
  }

  comboIndex += 1;
};

export const expand = async (
  graph: Graph,
  item: Item,
  target: HTMLElement,
  setGraphInfo: (info: { nodeCount?: number | undefined; nodeCountAPI?: number | undefined }) => void
) => {
  const textContent = target.closest('.row')?.firstElementChild?.textContent?.trim();

  const [label, direction] = textContent?.split(' ') ?? [];

  const nodeId = (item._cfg?.model as { id: string })?.id ?? '';

  await expandByNodeData(graph, item, nodeId, label, direction, setGraphInfo);
};

export const createCombos = (graph: Graph) => {
  if (graph.getCombos().length) {
    removeCombos(graph);
  } else {
    // Step 1: Group Nodes by Color
    const nodesByType = new Map<string, INode[]>();

    graph.getNodes().forEach((node) => {
      const type = node.getModel().nodeType as string;
      if (!nodesByType.has(type)) {
        nodesByType.set(type, []);
      }
      nodesByType.get(type)?.push(node);
    });

    // Step 2: Create Combos for Each Group
    nodesByType.forEach((nodes, type) => {
      const comboId = `combo-${type}`;
      if (!graph.findById(comboId)) {
        graph.createCombo(
          {
            id: comboId,
            label: nodes[0].getModel().nodeTypeName as string,
            labelCfg: {
              style: {
                fontSize: 60,
                fontWeight: 500,
              },
            },
            type: 'circle',
            style: {
              stroke: nodes[0].getModel().color as string,
              fill: nodes[0].getModel().color as string,
              fillOpacity: 0.2,
            },
          },
          nodes.map((a) => a.getID())
        );
      }

      // Step 2.1: Move Nodes to Their Respective Combos
      nodes.forEach((node) => {
        graph.updateItem(node.getID(), {
          comboId: comboId,
          style: {
            fill: node.getModel().img ? 'transparent' : 'white',
          },
        });
      });
    });

    // Step 3: Calculate Combo Layout Positions
    const totalWidth = graph.getCombos().reduce((a, c) => a + c?.getBBox().width, 0);

    // Calculate the starting X position for the first combo
    let startX = -totalWidth / 2;

    // Position combos in a horizontal line
    graph.getCombos().forEach((comboId) => {
      const comboItem = graph.findById(comboId.getID());
      graph.updateItem(comboId, { x: startX + comboItem.getBBox().width / 2, y: 0 });
      startX += comboItem.getBBox().width + 20; // Update the starting X position for the next combo
    });

    // Apply layout to nodes within each combo in a grid layout with nodes close to each other
    graph.getCombos().forEach((comboId) => {
      const comboItem = graph.findById(comboId.getID());
      const comboBBox = comboItem.getBBox();

      // Get the nodes within the combo
      const nodesInCombo = comboId.getNodes();

      // Calculate the number of rows and columns based on the number of nodes
      const numRows = Math.ceil(Math.sqrt(nodesInCombo.length));
      const numCols = Math.ceil(nodesInCombo.length / numRows);

      // Adjust the width and height of each cell in the grid to bring nodes close
      const cellWidth = comboBBox.width / (numCols * 2.2); // Adjust this factor for spacing
      const cellHeight = comboBBox.height / (numRows * 2.2); // Adjust this factor for spacing

      // Initialize variables for tracking the current row and column
      let currentRow = 0;
      let currentCol = 0;

      nodesInCombo.forEach((node) => {
        // Calculate the position of the node within the grid with nodes close together
        const x = comboBBox.minX + currentCol * cellWidth + cellWidth / 2;
        const y = comboBBox.minY + currentRow * cellHeight + cellHeight / 2;

        graph.updateItem(node.getID(), { x, y });

        // Move to the next column or row
        currentCol++;
        if (currentCol >= numCols) {
          currentCol = 0;
          currentRow++;
        }
      });
    });

    clearCanvas(graph);

    setTimeout(() => {
      graph.fitCenter(true);
      graph.fitView(
        [window.innerWidth / 2, window.innerHeight / 2],
        { ratioRule: 'max', direction: 'both', onlyOutOfViewPort: true },
        true
      );
    }, 1500);
  }
};

export const removeCombos = (graph: Graph) => {
  const comboSelect = graph.getCombos().filter((c) => c.getID() !== 'combo-select');

  if (comboSelect.length) {
    comboSelect.forEach((combo) => {
      graph.uncombo(combo.getID() as string);
    });
  }

  clearCanvas(graph);

  graph.updateLayout(
    layoutConfig['radial'] as LayoutConfig,
    'center',
    { x: window.innerWidth / 2, y: window.innerHeight / 2 },
    true
  );

  graph.on('afterlayout', () => {
    graph.fitCenter(true);
    graph.fitView(
      [window.innerWidth / 2, window.innerHeight / 2],
      { ratioRule: 'min', direction: 'both', onlyOutOfViewPort: true },
      true
    );
  });
};

export const clearCanvas = (graph: Graph) => {
  graph.setAutoPaint(false);
  graph.refreshPositions();
  graph.getNodes().forEach(function (node) {
    graph.clearItemStates(node);
  });
  graph.getEdges().forEach(function (edge) {
    graph.clearItemStates(edge);
  });
  graph.getCombos().forEach(function (combo) {
    graph.clearItemStates(combo);
  });
  graph.paint();
  graph.setAutoPaint(true);
};

export const removeFakeEdge = (graph: IGraph) => {
  const edges = graph.getEdges();
  const fake_edge: IEdge[] | undefined = edges?.filter((e) => e.getID().includes('edge-'));
  if (fake_edge?.length) fake_edge.forEach((edge) => graph.removeItem(edge?.getID()));
};

export const addEdges: AddEdges = (graph, nodeId, edges) => {
  edges?.forEach(({ source_id: source, target_id: target, ...edge }) => {
    graph.addItem('edge', {
      source,
      target,
      type: source === nodeId && target === nodeId ? 'loop' : 'quadratic',
      ...edge,
      labelCfg: edgeLabelCfgStyle,
    });
  });
};

export const updateConnector = (graph: Graph) => {
  const edges = graph.save().edges as Edge[];

  initConnector(edges);

  graph.getEdges().forEach((edge, i) => {
    graph.updateItem(edge, {
      curveOffset: edges[i].curveOffset,
      curvePosition: edges[i].curvePosition,
    });
  });
};

export const graphRender = (graph: Graph, isMain?: boolean) => {
  if (!graph || typeof graph.render !== 'function') {
    return;
  }

  graph.destroyLayout();

  const [xPos, yPos] = [window.innerWidth / 2, window.innerHeight / 2];

  if (!isMain) {
    graph.updateLayout(layoutConfig['radial'], 'center', { x: xPos, y: yPos }, true);
  }

  graph.render();

  graph.on('afterrender', () => {
    graph.fitCenter(true);
    graph.fitView([xPos, yPos], { ratioRule: 'max', direction: 'both', onlyOutOfViewPort: true }, true);
  });
};

const isSafariOrFirefox = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (/safari/.test(userAgent) && !/chrome/.test(userAgent)) || /firefox/.test(userAgent);
};

export const gpuEnabled = !isSafariOrFirefox();
