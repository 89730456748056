import { useMutation } from '@tanstack/react-query';
import client from 'api/client';
import { RequestTypes } from 'api/types';
import { URL_POST_EXTERNAL_FORM } from './constant';
import { TExternalFormData } from './types';

/**
 * The mutation function to create a form property.
 *
 * @param data - An object containing the `id` {form id} and formData {nodes[], edges[], files[]}
 */

export const useCreateExternalForm = () => {
  return useMutation({
    mutationFn: (data: TExternalFormData) =>
      client[RequestTypes.Post](URL_POST_EXTERNAL_FORM.replace(':id', data.id), data?.formData),
  });
};
