import { useQuery } from '@tanstack/react-query';
import { URL_GET_TARGET_NODES_BY_ID } from './constant';
import client from 'api/client';
import { RequestTypes } from 'api/types';
import { TTargetNodesData, TUseGetTargetNodesById } from './types';

/**
 * Custom hook to fetch the target nodes of app forms connection.
 *
 * @param params - The parameters for filtering target.
 *
 * @returns An object containing the fetched data and loading state.
 *
 */

export const useGetTargetNodesById: TUseGetTargetNodesById = (params, options) => {
  const API_URL = URL_GET_TARGET_NODES_BY_ID.replace(':id', params.id);

  const results = useQuery<TTargetNodesData, Error>({
    queryKey: [API_URL, params],
    queryFn: async () => {
      const response = await client[RequestTypes.GET](API_URL, { params: { search: params?.search } });
      return response.data;
    },
    ...options,
  });

  const { data, isLoading } = results;

  return {
    data: data ?? ({} as TTargetNodesData),
    isLoading,
  };
};
