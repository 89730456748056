export const ANALYTICS_TITLE = 'Analytics';
export const ANALYTICS_DESCRIPTION =
  'Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.';

export const DOCUMENT_REPOSITORY_TITLE = 'Document repository';
export const DOCUMENT_REPOSITORY_DESCRIPTION =
  'The Document Repository Section is a centralized location for managing, searching, tagging, and visualizing various document types. It features advanced search capabilities, automatic tag generation, and interactive visualizations to enhance document organization and retrieval.';

export const APP_FORMS_TITLE = 'Application Forms';
export const APP_FORMS_DESCRIPTION =
  'Use this section to create, customize, and send application forms, streamlining data collection.';

export const JIRA_TASK_LISTS_HEADER_TITLE = 'Jira’s Tasks List';
export const JIRA_TITLE = 'Jira';
export const JIRA_CONNECTED_TITLE = 'Jira Integration';
export const JIRA_NOT_CONNECTED_TITLE = 'Connect to Jira';
export const JIRA_DESCRIPTION =
  'Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.';

// export const SYNC_PROPERTIES = 'Sync Properties';
// export const SYNC_PROPERTIES_DESCRIPTION =
//   "It will sync your node data. If they don't have the same structure, it will adjust accordingly.";
