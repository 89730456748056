import React from 'react';
import { AppFormsProvider } from 'context/app-forms';
import { Col, Row } from 'antd';
import { LeftSectionForm } from './components/left-section-form';
import { CreateForm } from './components/right-section-form/create-app-form';

export const AppFormCreate: React.FC = () => {
  return (
    <AppFormsProvider>
      <Row gutter={[0, 0]} style={{ width: '100%' }}>
        <Col flex="0 0 420px">
          <LeftSectionForm />
        </Col>
        <Col flex="1">
          <CreateForm />
        </Col>
      </Row>
    </AppFormsProvider>
  );
};
