import React, { memo } from 'react';
import { HeaderForm } from './styled';
import { Text } from 'components/typography';
import { IAppFormProps } from '../type';

export const Header: React.FC<IAppFormProps> = memo(({ url, title, description }) => {
  return (
    <HeaderForm url={url}>
      <Text style={{ fontSize: 22, fontWeight: 700 }}>{title}</Text>
      <Text style={{ fontSize: 14, fontWeight: 400 }}>{description}</Text>
    </HeaderForm>
  );
});
