import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Text } from 'components/typography';
import { useAppForms } from 'context/app-forms';
import { useGetAppFormLeftBar } from 'api/app-forms/use-get-left-bar-data';
import { Empty, Flex } from 'antd';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';
import { CheckBoxGroups } from 'components/checkbox/groups';
import { ConnectionOneDirection } from 'components/layouts/components/data-sheet/utils';
import { LeftBarContent } from '../style';
import { ILeftBarOptions } from '../type';
import { COLORS } from 'helpers/constants';

enum RenderType {
  PROPERTIES = 'Properties',
  CONNECTIONS = 'Connections',
}

export const NodeTypeProperties: React.FC = () => {
  const [propertiesValue, setPropertiesValue] = useState<string[]>([]);
  const [connectionsValue, setConnectionsValue] = useState<string[]>([]);

  const { nodeTypeId, handleAction, form } = useAppForms();

  const { data, isLoading } = useGetAppFormLeftBar(nodeTypeId, {
    enabled: !!nodeTypeId.length,
  });

  const options = useMemo(
    () =>
      data?.properties?.map((item) => ({
        label: (
          <Flex align="center" gap={8}>
            <Text style={{ fontSize: 18 }}>{item.name}</Text>
            <Text style={{ fontSize: 14, color: COLORS.SECONDARY.MAGENTA }}>({item.ref_property_type_id})</Text>
          </Flex>
        ),
        value: item.id,
      })) || [],
    [data?.properties]
  );

  const connectionOptions = useMemo(
    () =>
      data?.sources?.map((item) => ({
        label: (
          <Flex vertical>
            <Text style={{ fontSize: 18 }}>{item.name}</Text>
            <Flex gap={8} align="center">
              <Text style={{ fontSize: 12, color: item?.source?.color }}>{item.source.name}</Text>
              <ConnectionOneDirection fill={item?.source?.color} />
              <Text style={{ fontSize: 12, color: item?.target?.color }}>{item.target.name}</Text>
            </Flex>
          </Flex>
        ),
        value: item.id,
      })) || [],
    [data?.sources]
  );

  const handlePropertyChange = useCallback(
    (keys: string[]) => {
      setPropertiesValue(keys);

      const selectedProperties = keys
        .map((id) => data?.properties?.find((item) => item.id === id))
        .filter((property): property is (typeof data.properties)[number] => property !== null);

      handleAction({
        type: APP_FORM_ACTIONS.ADD_FORM_PROPERTIES,
        payload: selectedProperties,
      });
    },
    [data, handleAction]
  );

  const handleConnectionChange = useCallback(
    (keys: string[]) => {
      setConnectionsValue(keys);

      const selectedConnections = keys
        ?.map((id) => {
          const connection = data?.sources?.find((item) => item.id === id);
          return connection ? { id: connection.id, name: connection.name } : null;
        })
        .filter((connection): connection is { id: string; name: string } => connection !== null);

      handleAction({
        type: APP_FORM_ACTIONS.ADD_FORM_EDGES,
        payload: selectedConnections,
      });
    },
    [data?.sources, handleAction]
  );

  useEffect(() => {
    if (form) {
      setPropertiesValue(form.form_properties?.map((item) => item?.property_type?.id) || []);
      setConnectionsValue(form.form_edges?.map((item) => item?.project_edge_type_id) || []);
    }
  }, [form]);

  const renderSection = useCallback(
    (title: string, options: Array<ILeftBarOptions>, value: string[], onChange: (keys: string[]) => void) => (
      <Flex vertical gap={12}>
        <Text style={{ fontSize: 18 }}>{title}</Text>
        <LeftBarContent>
          {options.length > 0 ? (
            <CheckBoxGroups
              options={options}
              isDisabled={!form.id}
              value={value}
              onChange={onChange}
              style={{ display: 'flex', flexDirection: 'column' }}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No options available" />
          )}
        </LeftBarContent>
      </Flex>
    ),
    [form.id]
  );

  if (isLoading) return null;

  return (
    <>
      {renderSection(RenderType.PROPERTIES, options, propertiesValue, handlePropertyChange)}
      {renderSection(RenderType.CONNECTIONS, connectionOptions, connectionsValue, handleConnectionChange)}
    </>
  );
};
