import { StatusActionType } from 'helpers/constants';
import { TAppFormState, TFormInitialState } from './types';

/**
 * @description Represent App form item type
 *
 * @see PROPERTY
 * @see EDGE
 */

export enum FormItemType {
  PROPERTY = 'form_properties',
  EDGE = 'form_edges',
}

/**
 * @description Represents the initial state for form .
 *
 * @typedef {Object} TFormInitialState
 *
 * @property {Object} id - app form id
 * @property {string} user_id - app form owner id
 * @property {Array<Object>} project_type_id - app form project node type id
 * @property {string} name - app form name
 * @property {string} description - app form description
 * @property {string} status - app form status default disable
 * @property {string | null} background_image - app form background image
 * @property {Array<Object>} form_properties - app form properties
 * @property {Array<Object>} form_edges - app form edges
 * @property {boolean} isUpdated - app form updated or not
 */

export const formInitialState: TFormInitialState = {
  id: '',
  user_id: '',
  project_id: '',
  project_type_id: '',
  description: '',
  name: '',
  expired_date: null,
  status: StatusActionType.DISABLE,
  background_image: null,
  form_properties: [],
  form_edges: [],
};

/**
 * @description Represents the initial state for App Forms .
 *
 * @typedef {Object} TAppFormState
 *
 * @property {Object} params - pagination and search
 * @property {string} nodeTypeId - nodeType id
 * @property {Array<Object>} formLists - form lists array
 * @property {string} appFormId - form id
 * @property {Object} form - form object
 */

export const initialState: TAppFormState = {
  params: { search: undefined, page: 1, size: 20 },
  nodeTypeId: '',
  appFormId: '',
  form: formInitialState,
  isShowView: false,
};
