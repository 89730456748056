import client from 'api/client';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { RequestTypes, TUseManageLeftBarFilters } from 'api/types';
import {
  GET_LEFT_BAR_FILTERS_LISTS,
  GET_PROJECT_NODE_TYPES_LIST,
  GET_PUBLIC_PROJECT_NODE_TYPES_LIST,
  URL_EXTERNAL_LEFT_BAR_FILTERS,
} from './constant';
import { useIsPublicPage } from 'hooks/use-is-public-page';

/**
 * @description Hook to fetch project node types or left bar filters based on the page type and shared status.
 *
 * @param id - Node type or filter ID (optional).
 * @param isShared - Whether the page is shared (default: false).
 * @param options - Additional options for the React Query hook.
 * @returns {isLoading, data} - Query results and loading state.
 */

export const useManageLeftBarFilters: TUseManageLeftBarFilters = (id, isShared = false, options) => {
  const { id: projectId } = useParams();
  const isPublicPage = useIsPublicPage();

  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const BASE_URL_TEMPLATE = isShared
    ? URL_EXTERNAL_LEFT_BAR_FILTERS
    : id
    ? GET_LEFT_BAR_FILTERS_LISTS
    : isPublicPage
    ? GET_PUBLIC_PROJECT_NODE_TYPES_LIST
    : GET_PROJECT_NODE_TYPES_LIST;

  let baseUrl = BASE_URL_TEMPLATE.replace(!isShared ? ':project_id' : ':id', projectId || '');

  if (id) {
    baseUrl += `?id=${id}`;
  }

  const queryFn = async () => {
    const response = await client[RequestTypes.GET](baseUrl, {
      params: { token },
    });

    return response.data;
  };

  const { isLoading, data } = useQuery({
    queryKey: ['visualization', baseUrl],
    queryFn,
    enabled: Boolean(projectId),
    ...options,
  });

  return { isLoading, data };
};
