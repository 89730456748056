import { Flex, Modal, Button } from 'antd';
import React from 'react';
import { TDeleteModalProps } from './types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { COLORS } from 'helpers/constants';

export const DeleteModal: React.FC<TDeleteModalProps> = ({ isOpen, isLoading, title, text, onClose, onFinish }) => {
  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>{title}</div>}
      open={isOpen}
      onOk={onFinish}
      onCancel={onClose}
      footer={null}
      confirmLoading={isLoading}
    >
      <Flex justify="center">
        <ExclamationCircleOutlined style={{ fontSize: 60, color: COLORS.SECONDARY.PURPLE_MIDDLE }} />
      </Flex>
      <div style={{ textAlign: 'center', fontSize: 16, padding: 16 }}>{text}</div>
      <Flex vertical gap={16}>
        <Button type="primary" onClick={onFinish}>
          Delete
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </Flex>
    </Modal>
  );
};
