import React, { useCallback, useMemo, useState } from 'react';
import { Skeleton } from 'antd';
import { useGetData } from 'api/visualisation/use-get-data';
import { useGetAllVisualizationById } from 'api/visualization-history/use-get-visualization-by-id';
import { SiderCollapse } from 'components/collapse/sider-collapse';
import { useGraph } from 'components/layouts/components/visualisation/wrapper';
import { createNodesTree } from 'components/layouts/components/data-sheet/utils';
import { formattedData } from 'components/layouts/components/visualisation/helpers/format-node';
import { initData as initGraphData } from 'components/layouts/components/visualisation/container/initial/nodes';
import { graphRender } from 'components/layouts/components/visualisation/helpers/utils';
import { useManageLeftBarFilters } from 'api/visualisation/use-manage-left-bar-filters';
import { GraphData } from 'components/layouts/components/visualisation/types';
import { NodeTypesView } from '../../data-sheet/components/node-types-view';
import { NodesHeader } from '../../data-sheet/components/nodes-header';
import { useManageFilters } from './use-manage-filters';
import { useVisualizationHistory } from 'context/visualization-history';
import { ACTIONS } from 'context/visualization-history/reducer';
import { AnyObject } from 'antd/es/_util/type';
import { NODE_LIMIT } from 'helpers/constants';
import { useGetExternalSharedData } from 'api/visualisation/use-get-external-shared-data';

export const Filters: React.FC<{ height?: string; isShared?: boolean }> = ({ height, isShared = false }) => {
  const [searchVisible, setSearchVisible] = useState(false);

  const { visualizationId, filterCheckedList, handleAction } = useVisualizationHistory();
  const { graph, setGraphInfo, graphInfo } = useGraph() ?? {};

  const { data: nodeTypes, isLoading } = useManageLeftBarFilters(visualizationId, isShared);

  const { data } = useGetAllVisualizationById(visualizationId, {
    enabled: !!visualizationId && !isShared,
  });

  const externalData = useGetExternalSharedData(isShared);
  const internalData = useGetData({ enabled: !isShared });

  const { nodes: nodesList, edges: edgesList, relationsCounts, savedGraph } = isShared ? externalData : internalData;

  const { mutate } = useManageFilters(
    { visualizationId, isShared },
    {
      onSuccess: ({ data }: AnyObject) => {
        let [results, count] = [{} as GraphData, 0];
        if (!visualizationId && !isShared) {
          results = formattedData(data.nodes, data.edges, data?.relationsCounts);
          count = nodesList?.length || count;
        } else {
          results = formattedData(data?.data?.nodes, data?.data?.edges, data?.data?.relationsCounts, data?.savedGraph);
          count = data?.data?.nodes?.length;
        }

        initGraphData(graph, results);

        graphRender(graph);

        setGraphInfo({
          nodeCount: count,
        });
      },
    }
  );

  const initData = useMemo(() => {
    if (!visualizationId) {
      return formattedData(nodesList || [], edgesList || [], relationsCounts, savedGraph);
    } else {
      return formattedData(
        data?.data?.nodes || [],
        data?.data?.edges || [],
        data?.data?.relationsCounts || {},
        data?.savedGraph
      );
    }
  }, [data, edgesList, nodesList, relationsCounts, savedGraph, visualizationId]);

  const onUpdateCheckedLists = useCallback(
    (checkedList: string[]) => {
      handleAction({
        type: ACTIONS.UPDATE_FILTERS_LIST_VISUALIZATION,
        payload: checkedList,
      });
    },
    [handleAction]
  );

  const onCheck = async (checkedKeys: string[]) => {
    if (checkedKeys.length) {
      mutate(checkedKeys);
    } else {
      initGraphData(graph, initData);

      graphRender(graph, !!visualizationId);
      setGraphInfo({
        nodeCountAPI: (graphInfo?.nodeCountAPI ?? 0) > NODE_LIMIT ? NODE_LIMIT : graphInfo?.nodeCountAPI,
      });
    }
    onUpdateCheckedLists(checkedKeys);
  };

  return isLoading ? (
    <Skeleton />
  ) : (
    <SiderCollapse
      defaultActiveKey="1"
      height={height}
      panels={[
        {
          header: <NodesHeader setSearchVisible={setSearchVisible} />,
          key: '1',
          children: (
            <NodeTypesView
              onCheck={onCheck}
              nodesList={createNodesTree(nodeTypes || [], false)}
              searchVisible={searchVisible}
              checkedKeys={filterCheckedList}
              setSearchVisible={setSearchVisible}
              isCheckable={true}
              noColors={true}
            />
          ),
        },
      ]}
    ></SiderCollapse>
  );
};
