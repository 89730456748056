import React, { useEffect, useState, useCallback } from 'react';
import { DatePicker, Drawer, Form, Button, Select, Flex } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { COLORS, dateFormat } from 'helpers/constants';
import { useShareVisualization } from 'api/visualisation/use-share-visualization';
import { useVisualizationHistory } from 'context/visualization-history';
import { Text } from 'components/typography';
import { ACTIONS } from 'context/visualization-history/reducer';
import { ShareButtonType, ShareDrawerText, ShareOptions, ShareDataStatus } from './constant';
import { ShareLink } from './share-link';
import { TSharedData } from 'context/visualization-history/types';

const { SAVE_AS, UPDATE } = ShareButtonType;
const { Info, Title, LinkTitle, FormStatus, FormStatusText } = ShareDrawerText;
const { DISABLE_SPECIFIC, ENABLE } = ShareDataStatus;

export const ShareDrawer: React.FC = () => {
  const [status, setStatus] = useState<string>(ENABLE);
  const [isEnableSpecific, setIsEnableSpecific] = useState(false);
  const [form] = Form.useForm();

  const { mutate: createShareFn } = useShareVisualization();
  const { visualizationId, handleAction, openShareDrawer, sharedData } = useVisualizationHistory();

  const disabledDate: RangePickerProps['disabledDate'] = (current) => current && current < dayjs().endOf('day');

  const onSetSharedData = useCallback(
    (data: TSharedData | null) => {
      handleAction({
        type: ACTIONS.SET_SHARED_DATA,
        payload: data,
      });
    },
    [handleAction]
  );

  const onHandleSelectStatus = (value: string) => {
    setStatus(value);
    setIsEnableSpecific(value === DISABLE_SPECIFIC);
  };

  const updateExpiredDateField = useCallback(() => {
    if (isEnableSpecific) {
      const date = sharedData?.expired_date ? dayjs(sharedData.expired_date) : dayjs().add(1, 'day').startOf('day');

      form.setFieldsValue({ expired_date: date });
    }
  }, [isEnableSpecific, form, sharedData]);

  const onFinish = (values: { expired_date: Dayjs }) => {
    const expired_date = isEnableSpecific && values.expired_date ? values.expired_date.format('MM/DD/YYYY') : undefined;

    if (!visualizationId) return;

    createShareFn(
      {
        id: sharedData?.id,
        graph_id: visualizationId,
        expired_date,
        status,
      },
      {
        onSuccess: (data) => {
          onSetSharedData(data?.data);
        },
      }
    );
  };

  useEffect(() => {
    updateExpiredDateField();
  }, [sharedData, updateExpiredDateField]);

  return (
    <Drawer
      getContainer={false}
      open={openShareDrawer}
      title={<Text style={{ fontSize: 18 }}>{Title}</Text>}
      bodyStyle={{ padding: '32px 18px 18px' }}
      drawerStyle={{
        background: COLORS.MAIN_GRAY,
        boxShadow: '10px 10px 10px 0px rgba(111, 111, 111, 0.10) inset',
      }}
      onClose={() => handleAction({ type: ACTIONS.OPEN_SHARE_DRAWER, payload: false })}
    >
      <Flex vertical gap={16}>
        <Text style={{ fontSize: 18, fontWeight: 700 }}>{LinkTitle}</Text>
        <Text style={{ fontSize: 16, fontWeight: 400 }}>{Info}</Text>
        <Text style={{ fontSize: 16, fontWeight: 700 }}>{FormStatus}</Text>
        <Text style={{ fontSize: 16, fontWeight: 400 }}>{FormStatusText}</Text>
        <Select options={ShareOptions} defaultValue={ENABLE} onSelect={onHandleSelectStatus} />
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{
            expired_date: isEnableSpecific
              ? sharedData?.expired_date
                ? dayjs(sharedData.expired_date)
                : dayjs().add(1, 'day').startOf('day')
              : undefined,
          }}
        >
          {isEnableSpecific && (
            <Form.Item name="expired_date" label="Expiration Date" rules={[{ required: true }]}>
              <DatePicker format={dateFormat} disabledDate={disabledDate} placeholder="Select expiration date" />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              {!sharedData ? SAVE_AS : UPDATE}
            </Button>
          </Form.Item>
        </Form>

        {sharedData?.id && <ShareLink data={sharedData} onSetSharedData={onSetSharedData} />}
      </Flex>
    </Drawer>
  );
};
