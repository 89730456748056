import { useQuery } from '@tanstack/react-query';
import client from 'api/client';
import { RequestTypes } from 'api/types';
import { useParams } from 'react-router-dom';
import { URL_POST_EXTERNAL_FORM } from './constant';
import { TUseGetFormId } from './types';

/**
 * Custom hook to fetch the app form by id external.
 *
 * @param none
 *
 * @returns An object containing the fetched data and loading state.
 *
 */

export const useGetExternalAppForm: TUseGetFormId = () => {
  const { id } = useParams();

  const API_URL = URL_POST_EXTERNAL_FORM.replace(':id', id || '');

  const results = useQuery({
    queryKey: [API_URL],
    queryFn: () => client[RequestTypes.GET](API_URL),
    select: (data) => data.data,
    enabled: !!id,
    retry: 1,
  });

  const { data, isLoading, isFetched, isError } = results;

  return {
    data,
    isLoading,
    isFetched,
    isError,
  };
};
