import { useQuery } from '@tanstack/react-query';
import { URL_EXTERNAL_SHARE_VISUALIZATION_DATA } from './constant';
import { useParams } from 'react-router-dom';
import { RequestTypes, TSaveGraph } from 'api/types';
import client from 'api/client';
import { Edges, Nodes } from './use-get-data';
import { PATHS } from 'helpers/constants';

type Result = {
  nodes: Nodes | undefined;
  edges: Edges | undefined;
  count: number;
  savedGraph: TSaveGraph;
  relationsCounts: { [key: string]: number };
  isLoading: boolean;
};

export const useGetExternalSharedData = (enabled: boolean): Result => {
  const { id } = useParams();
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const BASE_URL = URL_EXTERNAL_SHARE_VISUALIZATION_DATA.replace(':id', id || '');

  const results = useQuery({
    queryKey: [BASE_URL, token],
    queryFn: () => {
      return client[RequestTypes.GET](BASE_URL, {
        params: { token },
      });
    },
    select: (data) => data?.data || {},
    enabled,
    retry: 1,
    onError: () => {
      window.location.href = PATHS.ERROR_NOT_FOUND;
    },
  });

  const { data, isLoading } = results;

  const { nodes, edges, relationsCounts, count } = data?.data || {};

  return {
    isLoading,
    nodes,
    edges,
    relationsCounts,
    count,
    savedGraph: data?.savedGraph,
  };
};
