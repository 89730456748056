import G6, { Graph, LayoutConfig } from '@antv/g6';
import {
  edgeLabelCfgStyle,
  layoutConfig,
  nodeLabelCfgStyle,
} from 'components/layouts/components/visualisation/helpers/constants';
import { renderTooltipModal } from 'components/layouts/components/visualisation/helpers/tooltip';
import { COLORS } from 'helpers/constants';
import { IGraphG6Config } from 'pages/project-visualisation/type';

export const GraphG6Config = ({ container, width, height, setNodeId }: IGraphG6Config): Graph => {
  const graph = new G6.Graph({
    container,
    width: width - 65,
    height: height,
    layout: layoutConfig['radial'] as LayoutConfig,
    defaultNode: {
      style: {
        lineWidth: 3,
        fill: COLORS.PRIMARY.WHITE,
      },
      clipCfg: {
        show: true,
        type: 'circle',
        r: 20,
        rx: 10,
        ry: 15,
        width: 15,
        height: 15,
      },
      labelCfg: {
        style: {
          ...nodeLabelCfgStyle.style,
          opacity: 0,
        },
        offset: 10,
        position: 'bottom',
      },
      size: 40,
    },
    defaultEdge: {
      labelCfg: {
        autoRotate: true,
        style: {
          ...edgeLabelCfgStyle.style,
          opacity: 0,
        },
      },
      style: {
        lineWidth: 2,
        stroke: COLORS.MAIN_GRAY_SILVER,
        endArrow: {
          fill: COLORS.MAIN_GRAY_SILVER,
          path: G6.Arrow.triangle(10, 15, 5),
          d: 5,
        },
      },
    },
    modes: {
      default: [
        'drag-canvas',
        'drag-node',
        {
          type: 'tooltip',
          formatText: (model: { [key: string]: unknown }) => {
            return renderTooltipModal(model);
          },
          offset: 10,
        },
        { type: 'zoom-canvas', minZoom: 0.2, maxZoom: 2 },
      ],
    },
  });

  graph.on('node:dblclick', (evt) => {
    if (setNodeId) {
      setNodeId(evt?.item?.getID() as string);
    }
  });

  return graph;
};
