import { useQuery } from '@tanstack/react-query';
import client from 'api/client';
import { RequestTypes } from 'api/types';
import { URL_APP_FORMS_LISTS } from './constant';
import { TUseGetAppFormsLists, TReturnDataAppFormLists } from './types';

/**
 * Custom hook to fetch the list of app forms.
 *
 * @param params - The parameters for filtering or paginating the app forms.
 *
 * @returns An object containing the fetched data and loading state.
 *
 */

export const useGetAppFormsLists: TUseGetAppFormsLists = (params, options) => {
  const results = useQuery<TReturnDataAppFormLists, Error>({
    queryKey: [URL_APP_FORMS_LISTS, params],
    queryFn: async () => {
      const response = await client[RequestTypes.GET](URL_APP_FORMS_LISTS, { params });
      return response.data;
    },
    ...options,
  });

  const { data, isLoading } = results;

  return { data, isLoading };
};
