import React from 'react';
import { ProjectProvider } from 'context/project-context';
import { VisualizationHistoryProvider } from 'context/visualization-history';
import { Outlet } from 'react-router-dom';

export const ExternalRoute: React.FC = () => {
  return (
    <ProjectProvider>
      <VisualizationHistoryProvider>
        <Outlet />
      </VisualizationHistoryProvider>
    </ProjectProvider>
  );
};
