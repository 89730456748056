import React, { useState, useEffect } from 'react';
import { Flex, Form, Input, Upload, UploadFile } from 'antd';
import { Text } from 'components/typography';
import { AppFormItem } from '../style';
import { LinkOutlined } from '@ant-design/icons';
import { useAppForms } from 'context/app-forms';
import { useUpdateAppForm } from 'api/app-forms/use-update-app-form';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';
import { TextArea } from 'components/input/text-area';

export const Header: React.FC = () => {
  const { form, handleAction } = useAppForms();
  const [isUpdate, setIsUpdate] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [formData, setFormData] = useState({
    name: form.name,
    description: form.description,
  });
  const [editMode, setEditMode] = useState({
    name: false,
    description: false,
  });

  const { mutate: updateFn } = useUpdateAppForm();

  useEffect(() => {
    if (form?.background_image) {
      setFileList([
        {
          uid: '-1',
          name: 'background_image',
          url: process.env.REACT_APP_AWS_URL?.concat('', String(`${form.background_image}?t=${Date.now()}`)),
        },
      ]);
    }
  }, [form.background_image, isUpdate]);

  const handleChange = (type: 'name' | 'description') => {
    setEditMode((prev) => ({ ...prev, [type]: true }));
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'name' | 'description'
  ) => {
    setFormData((prev) => ({ ...prev, [type]: e.target.value }));
  };

  const handleInputBlur = (type: 'name' | 'description') => {
    setEditMode((prev) => ({ ...prev, [type]: false }));
    updateFn({ formId: form.id, params: formData });
  };

  const handleKeyDown = (e: React.KeyboardEvent, type: 'name' | 'description') => {
    if (e.key === 'Enter') {
      setEditMode((prev) => ({ ...prev, [type]: false }));
      updateFn({ formId: form.id, params: formData });
    }
  };

  const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    if (fileList.length > 1) {
      setFileList([fileList[fileList.length - 1]]);
    } else {
      setFileList(fileList);
    }

    const formData = new FormData();

    fileList.forEach((file) => {
      if (file.originFileObj) {
        formData.append('file', file.originFileObj);
      }
    });

    updateFn(
      { formId: form.id, params: formData },
      {
        onSuccess: (data) => {
          handleAction({
            type: APP_FORM_ACTIONS.UPDATE_FORM_BACKGROUND,
            payload: data?.data?.background_image,
          });
          setIsUpdate(true);
          setTimeout(() => setIsUpdate(false), 1000);
        },
      }
    );
  };

  useEffect(() => {
    if (form) {
      setFormData({
        name: form?.name,
        description: form?.description,
      });
    }
  }, [form]);

  return (
    <Form
      style={{
        padding: 16,
        background: `url(${fileList[0]?.url})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <AppFormItem justify="space-between" align="center">
        <Flex vertical gap={16}>
          {editMode.name ? (
            <Input
              value={formData.name}
              onChange={(e) => handleInputChange(e, 'name')}
              onBlur={() => handleInputBlur('name')}
              onKeyDown={(e) => handleKeyDown(e, 'name')}
              style={{ width: 320, fontSize: 16, height: 30 }}
            />
          ) : (
            <Text onDoubleClick={() => handleChange('name')} style={{ fontSize: 18, fontWeight: 700 }}>
              {formData.name}
            </Text>
          )}
          {editMode.description ? (
            <TextArea
              value={formData.description}
              onChange={(e) => handleInputChange(e, 'description')}
              onBlur={() => handleInputBlur('description')}
              onKeyDown={(e) => handleKeyDown(e, 'description')}
              maxLength={500}
              required
              cols={100}
              autoSize={{ minRows: 2 }}
            />
          ) : (
            <Text onDoubleClick={() => handleChange('description')} style={{ fontSize: 14, fontWeight: 700 }}>
              {formData.description}
            </Text>
          )}
        </Flex>

        <Form.Item name="file">
          <Upload
            fileList={fileList}
            maxCount={1}
            onChange={handleFileChange}
            beforeUpload={() => false}
            multiple={false}
          >
            <LinkOutlined />
            <Text style={{ fontSize: 16 }}>Attach file</Text>
          </Upload>
        </Form.Item>
      </AppFormItem>
    </Form>
  );
};
