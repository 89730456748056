import React from 'react';
import { Badge, Flex } from 'antd';
import { Text } from 'components/typography';
import { COLORS } from 'helpers/constants';
import { InfoCircleTwoTone } from '@ant-design/icons';

export const Info: React.FC = () => {
  return (
    <Flex vertical style={{ marginBottom: 10 }} gap={16}>
      <Flex gap={8}>
        <InfoCircleTwoTone />
        <Text style={{ color: COLORS.BLUE_10, fontSize: 14 }}>
          When you change node type must be create new app form
        </Text>
      </Flex>
      <Flex align="center" gap={16}>
        <Badge color={COLORS.SECONDARY.GREEN} />
        <Text style={{ fontSize: 14 }}>Form item saved</Text>
      </Flex>
      <Flex align="center" gap={16}>
        <Badge color={COLORS.SECONDARY.YELLOW} />
        <Text style={{ fontSize: 14 }}>Form item is changed</Text>
      </Flex>
      <Flex align="center" gap={16}>
        <Badge color={COLORS.SECONDARY.MAGENTA} />
        <Text style={{ fontSize: 14 }}>Form item not saved</Text>
      </Flex>
    </Flex>
  );
};
