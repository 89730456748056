import { useMemo } from 'react';
import { toolSetDataHandle } from '../helpers/utils';
import { ToolSetPanel } from '../components/tool-set-panel';
import { useAuth } from 'context/auth-context';

export const useToolsetData = (
  handleAnalyticsClick: VoidFunction,
  handleDocumentClick: VoidFunction,
  handleAppFormsClick: VoidFunction,
  handleJiraClick: VoidFunction
) => {
  const { user } = useAuth();

  return useMemo(
    () =>
      toolSetDataHandle({
        handleAnalyticsClick,
        handleDocumentClick,
        handleJiraClick,

        isJiraConnected: !!user?.jira_account_id,
        handleAppFormsClick,
      }).map((item, index) => <ToolSetPanel key={index} {...item} />),
    [handleAnalyticsClick, handleAppFormsClick, handleDocumentClick, handleJiraClick, user?.jira_account_id]
  );
};
