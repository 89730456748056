import { useMutation } from '@tanstack/react-query';
import client from 'api/client';
import { RequestTypes } from 'api/types';
import { URL_APP_FORMS_BY_ID } from './constant';
import { useParams } from 'react-router-dom';
import { TUpdateAppForm } from './types';

/**
 * The mutation function to update a app form data.
 *
 * @param data - An object containing the `id` (project ID) and id, params
 */

export const useUpdateAppForm = () => {
  const { id: project_id } = useParams();
  const API_URL = URL_APP_FORMS_BY_ID.replace(':project_id', project_id || '');
  return useMutation({
    mutationFn: (data: TUpdateAppForm) => client[RequestTypes.Put](API_URL.replace(':id', data.formId), data.params),
  });
};
