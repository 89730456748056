import { Navigate } from 'react-router-dom';

import { PATHS, screenSize } from 'helpers/constants';
import { useAuth } from 'context/auth-context';
import { HeaderSearch } from './components/header-search';
import { Logo } from 'components/logo';
import { OverviewProvider } from 'context/overview-context';
import { OverviewTabs } from 'components/tabs/overview-tabs';
import { ToolSetDrawer } from '../drawer/tool-set';
import { JiraProvider } from 'components/drawer/tool-set/components/jira/context';
import { OverivewContent, OverviewHeader, OverviewLayout } from './styles';

export const Overview = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to={PATHS.SIGN_IN} />;
  }

  return (
    <OverviewLayout className="layout">
      <OverviewHeader id="overview-header">
        <Logo margin={screenSize.xxl ? '0' : '15px 0'} />
        <HeaderSearch />
      </OverviewHeader>
      <OverivewContent>
        <OverviewProvider>
          <JiraProvider>
            <OverviewTabs />
            <ToolSetDrawer />
          </JiraProvider>
        </OverviewProvider>
      </OverivewContent>
    </OverviewLayout>
  );
};
