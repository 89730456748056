import { useQueryClient } from '@tanstack/react-query';
import { Button, Flex, Form } from 'antd';
import { URL_NODES_LIST } from 'api/node/constants';
import { useBulkUpdateNode } from 'api/node/use-nodes-bulk-updates';
import { FormItem } from 'components/form/form-item';
import { PropertyTypes } from 'components/form/property/types';
import { BooleanType } from 'components/form/type/boolean-type';
import { DateTimeType } from 'components/form/type/date-time-type';
import { DateType } from 'components/form/type/date-type';
import { DocumentType } from 'components/form/type/document-type';
import { EnumType } from 'components/form/type/enum-type';
import { LocationType } from 'components/form/type/location-type';
import { NumericType } from 'components/form/type/numeric-type';
import { RichTextType } from 'components/form/type/rich-text-type';
import { UrlType } from 'components/form/type/url-type';
import { VerticalSpace } from 'components/space/vertical-space';
import { Text } from 'components/typography';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BulkEditFormProps, BulkEditLocation, OnFinishValues } from 'types/menu-node';
import { TextType } from 'components/form/type/text-type';
import { URL_EDGES_NODE_DATA } from 'api/edges/constants';
import { useDataSheetWrapper } from 'components/layouts/components/data-sheet/wrapper';
import { ProjectTypePropertyReturnData } from 'api/types';

const Wrapper = styled.div`
  padding: 24px 24px 8px;
  width: 422px;
`;

export const BulkEditForm: React.FC<BulkEditFormProps> = ({ propertyId, setBulkEditOpened, data, name }) => {
  const params = useParams();
  const queryClient = useQueryClient();
  const { nodeTypeId } = useDataSheetWrapper();

  const project_type_id = data?.project_type_id;

  const [form] = Form.useForm();

  const { mutate: updateBulkNode } = useBulkUpdateNode({ name });

  const onHandleCancel = () => {
    setBulkEditOpened(false);
  };

  const onFinish = (values: OnFinishValues) => {
    const fieldKey = Object.keys(values)[0];
    const updatedValue = values[fieldKey];
    const locationData = (updatedValue as BulkEditLocation[])?.[0];

    updateBulkNode(
      {
        ...(name ? { edge_type_property_id: propertyId } : { project_type_property_id: propertyId }),
        value: (updatedValue as BulkEditLocation[])[0].address
          ? [
              {
                address: locationData?.address,
                location: {
                  longitude: locationData?.lng,
                  latitude: locationData?.lat,
                },
              },
            ]
          : name
          ? String(updatedValue as string[])
          : [String((updatedValue as string[])[0])],
      },

      {
        onSuccess: () => {
          name
            ? queryClient.invalidateQueries([
                URL_EDGES_NODE_DATA.replace(':edge_type_id', nodeTypeId || '').replace(':project_id', params.id || ''),
              ])
            : queryClient.invalidateQueries([
                URL_NODES_LIST.replace(':project_id', params.id || '').replace(
                  ':project_type_id',
                  project_type_id || ''
                ),
              ]);
          setBulkEditOpened(false);
        },
      }
    );
  };

  const renderPropertyNodes = () => {
    switch (data?.ref_property_type_id) {
      case PropertyTypes.ENUM:
        return <EnumType key={data.id} data={data} />;
      case PropertyTypes.Text:
        return <TextType key={data.id} data={data} />;
      case PropertyTypes.Location:
        return <LocationType key={data.id} data={data} />;
      case PropertyTypes.URL:
        return <UrlType key={data.id} data={data} />;
      case PropertyTypes.Integer:
      case PropertyTypes.Decimal:
        return <NumericType key={data.id} data={data} />;
      case PropertyTypes.Date:
        return <DateType key={data.id} data={data} />;
      case PropertyTypes.DateTime:
        return <DateTimeType key={data.id} data={data} />;
      case PropertyTypes.Boolean:
        return <BooleanType key={data.id} data={data} />;
      case PropertyTypes.RichText:
        return <RichTextType key={data.id} data={data} />;
      case PropertyTypes.Document:
        return <DocumentType key={data.id} data={data} />;
      default:
        return null;
    }
  };

  const renderPropertyConnection = () => {
    const matchingProperties = Array.isArray(data?.properties)
      ? data?.properties.filter((item: { id: string; ref_property_type_id: number }) => item?.id === propertyId)
      : [];
    return (
      <>
        {matchingProperties.map((item: ProjectTypePropertyReturnData | undefined) => {
          switch (item?.ref_property_type_id) {
            case PropertyTypes.Text:
              return <TextType key={item.id} data={item} />;
            case PropertyTypes.Integer:
            case PropertyTypes.Decimal:
              return <NumericType key={item.id} data={item} />;
            case PropertyTypes.Date:
              return <DateType key={data.id} data={item} />;
            case PropertyTypes.RichText:
              return <RichTextType key={item.id} data={item} />;
            case PropertyTypes.DateTime:
              return <DateTimeType key={data.id} data={item} />;
            default:
              return <></>;
          }
        })}
      </>
    );
  };

  return (
    <Wrapper>
      <Form
        name="project-node-type-property"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <Flex gap={30} vertical>
          <Text color="#232F6A">Bulk edit property values</Text>
          <Flex style={{ paddingLeft: 30 }} vertical>
            <VerticalSpace>{name ? renderPropertyConnection() : renderPropertyNodes()}</VerticalSpace>
            <Text color="#7F7474" style={{ fontSize: 13 }}>
              This action will update all values of the selected property
            </Text>
          </Flex>
          <FormItem>
            <Flex gap={30}>
              <Button block type="primary" htmlType="submit">
                Save
              </Button>

              <Button block type="text" onClick={onHandleCancel}>
                Cancel
              </Button>
            </Flex>
          </FormItem>
        </Flex>
      </Form>
    </Wrapper>
  );
};
