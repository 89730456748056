import React from 'react';
import { FormLists } from './components/form-lists';
import { Divider } from 'antd';
import { Header } from './components/header';
import { AppFormsProvider } from 'context/app-forms';

export const AppFormsList: React.FC = () => {
  return (
    <AppFormsProvider>
      <>
        <Header />
        <Divider />
        <FormLists />
      </>
    </AppFormsProvider>
  );
};
