import { useQuery } from '@tanstack/react-query';
import client from 'api/client';
import { useParams } from 'react-router-dom';
import { URL_GET_NODE_TYPES } from './constants';
import { TUseGetAllNodeTypes } from 'api/types';

export const useGetAllNodeTypes: TUseGetAllNodeTypes = () => {
  const { id } = useParams();

  const API_URL = URL_GET_NODE_TYPES.replace(':project_id', id || '');

  const result = useQuery({
    queryKey: [API_URL],
    queryFn: () => client.get(API_URL),
    select: (data) => data.data,
  });

  const { isLoading, data } = result;

  return {
    data: !isLoading ? data : ([] as TUseGetAllNodeTypes[]),
    isLoading,
  };
};
