import { useQuery } from '@tanstack/react-query';
import client from 'api/client';

export const GET_EDGE_CHECK_DATA = '/projects-edge-type/check-data/:id';

export const useGetACheckEdgeData = (params: { id: string; params?: string[] }, options: { enabled: boolean }) => {
  const url = GET_EDGE_CHECK_DATA.replace(':id', params.id || '');

  const result = useQuery({
    queryKey: [GET_EDGE_CHECK_DATA, url, params],
    queryFn: () => client.get(url, { params: params.params }),
    ...options,
  });

  const { data, isSuccess } = result;

  return {
    data: isSuccess ? data?.data : {},
    isSuccess,
  };
};
