import React, { useEffect } from 'react';
import { useGraphRef } from 'hooks/use-graph';
import { Wrapper } from './wrapper';
import { useGetData } from 'api/visualisation/use-get-data';
import { Spin } from 'antd';
import { useGraph } from 'components/layouts/components/visualisation/wrapper';
import { useVisualizationHistory } from 'context/visualization-history';
import { ACTIONS } from 'context/visualization-history/reducer';

export const Graph: React.FC = () => {
  const { graphInfo } = useGraph() ?? {};
  const { handleAction } = useVisualizationHistory();
  const { user_id, nodes, edges, count, relationsCounts, isInitialLoading } = useGetData();

  useEffect(() => {
    if (user_id) {
      handleAction({
        type: ACTIONS.SET_VISUALIZATION_USER,
        payload: { user_id },
      });
    }
  }, [handleAction, user_id]);

  const ref = useGraphRef({ nodes, edges, count, relationsCounts });

  return (
    <Spin style={{ maxHeight: '100%' }} spinning={isInitialLoading}>
      <Wrapper
        width={window.innerWidth}
        height={window.innerHeight - 150}
        isExpanded={graphInfo?.collapsedWidth}
        ref={ref}
      />
    </Spin>
  );
};
