import React, { useEffect } from 'react';
import { useAppForms } from 'context/app-forms';
import { Col, Row } from 'antd';
import { LeftSectionForm } from './components/left-section-form';
import { UpdateAppForm } from './components/right-section-form/update-app-form';
import { TFormInitialState } from 'context/app-forms/types';
import { useGetFormsById } from 'api/app-forms/use-get-forms-by-id';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';
import { Spinning } from 'components/spinning';

export const AppFormView: React.FC = () => {
  const { handleAction } = useAppForms();

  const { isLoading, data } = useGetFormsById();

  useEffect(() => {
    if (data) {
      handleAction({
        type: APP_FORM_ACTIONS.SET_FORM_DATA,
        payload: data as TFormInitialState,
      });
    } else {
      handleAction({
        type: APP_FORM_ACTIONS.RESET_FORM_DATA,
        payload: {},
      });
    }
  }, [data, handleAction]);

  if (isLoading) <Spinning />;

  return (
    <Row gutter={[0, 0]} style={{ width: '100%' }}>
      <Col flex="0 0 420px">
        <LeftSectionForm />
      </Col>
      <Col flex="1">
        <UpdateAppForm />
      </Col>
    </Row>
  );
};
