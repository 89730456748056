import { useMutation } from '@tanstack/react-query';
import { RequestTypes } from 'api/types';
import { URL_APP_FORMS_EDGE_BY_ID } from './constant';
import client from 'api/client';
import { useParams } from 'react-router-dom';
import { TFormType } from './types';

/**
 * The mutation function to create a form edge.
 *
 * @param data - An object containing the `id` (project ID) and query
 */

export const useCreateFormEdge = () => {
  const { id: projectId } = useParams();
  return useMutation({
    mutationFn: (data: { id: string; query: TFormType }) =>
      client[RequestTypes.Post](
        URL_APP_FORMS_EDGE_BY_ID.replace(':project_id', projectId || '').replace(':id', data.id),
        data.query
      ),
  });
};
