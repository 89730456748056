import { RequestTypes } from 'api/types';
import { useMutation, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import client from 'api/client';
import { errorMessage } from 'helpers/utils';
import { GetNeo4jData } from 'api/visualisation/use-get-data';
import { useIsPublicPage } from 'hooks/use-is-public-page';

export const MANAGE_FILTERS = '/neo4j/filters/:project_id';
export const MANAGE_FILTERS_PUBLIC = '/public/neo4j/filters/:project_id';
export const MANAGE_FILTERS_SAVED_GRAPHS = '/save-graphs/filter/:project_id/:id';
export const MANAGE_FILTERS_EXTERNAL_GRAPHS = '/external/graphs/filter/:id';

type Options = UseQueryOptions<string[], Error, GetNeo4jData>;

type TUseManageFilters = {
  visualizationId: string | undefined;
  isShared?: boolean;
};

export const useManageFilters = (data: TUseManageFilters, options: Options) => {
  const { visualizationId, isShared } = data;

  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const { id } = useParams();
  const queryClient = useQueryClient();
  const isPublicPage = useIsPublicPage();
  const isMain = !visualizationId;

  const mutation = useMutation<GetNeo4jData, unknown, string[]>({
    mutationFn: (values: string[]) => {
      const type = RequestTypes.Post;

      const urlFilter = isShared
        ? MANAGE_FILTERS_EXTERNAL_GRAPHS
        : isMain
        ? isPublicPage
          ? MANAGE_FILTERS_PUBLIC
          : MANAGE_FILTERS
        : MANAGE_FILTERS_SAVED_GRAPHS;

      let url = urlFilter.replace(':project_id', id || '');

      if (!isMain || isShared) {
        url = url.replace(':id', isShared ? id ?? '' : visualizationId ?? '');
      }

      const body = {
        ids: values,
        token: token,
      };

      return client[type](url, body);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([MANAGE_FILTERS.replace(':project_id', id || '')]);
      options?.onSuccess?.(data);
    },
    onError: errorMessage,
  });
  return mutation;
};
