import { MenuProps, Space } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { PATHS, pathMap } from 'helpers/constants';
import { HeaderSearch } from './components/header-search';
import { Logo } from 'components/logo';
import {
  ProjectHomeContainer,
  ProjectHomeContent,
  ProjectHomeHeader,
  ProjectHomeLayout,
  ProjectHomeMenu,
  ProjectHomeSider,
} from './styles';
import { projectHomeMenu } from './constant';

export const ProjectHome = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key);
  };

  const defaultSelected = pathMap[location.pathname] || PATHS.PROFILE;

  return (
    <ProjectHomeLayout>
      <ProjectHomeSider width={240} breakpoint="lg">
        <Logo margin="31px 63px" />
        <ProjectHomeMenu
          mode="inline"
          inlineIndent={63}
          selectedKeys={[defaultSelected]}
          onClick={onClick}
          items={projectHomeMenu}
        />
      </ProjectHomeSider>
      <ProjectHomeContainer>
        <ProjectHomeHeader>
          <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <HeaderSearch />
          </Space>
        </ProjectHomeHeader>
        {location.pathname === PATHS.PROFILE ? (
          <Outlet />
        ) : (
          <ProjectHomeContent>
            <Outlet />
          </ProjectHomeContent>
        )}
      </ProjectHomeContainer>
    </ProjectHomeLayout>
  );
};
