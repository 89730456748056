import { MenuProps } from 'antd';
import { ReactComponent as Delete } from 'components/icons/delete.svg';
import { ReactComponent as Edit } from 'components/icons/edit-pencil.svg';
import { ReactComponent as EditBulk } from 'components/icons/edit-bulk.svg';
import { MenuText } from 'components/typography';
import { DeleteTypePropertyModal } from 'components/modal/delete-type-property-modal';

import './index.css';
import { useTypeProperty } from 'pages/data-sheet/components/table-section/table-context';
import { TypePropertyActionKind } from 'pages/data-sheet/components/table-section/types';
import { useSetPropertyDefault } from 'api/project-node-type-property/use-set-property-default';
import { useDataSheetWrapper } from 'components/layouts/components/data-sheet/wrapper';
import { PropertyMenu } from './property-menu';
import { AddNodeTypePopover, BulkEditPopover } from 'components/popover';
import { AddTypePropertyForm } from 'components/form/type-property/add-type-property-form';
import { useState } from 'react';
import { ProgressBar } from '../progress-bar';
import { useImport } from '../../context/import-context';
import { BulkEditForm } from './bulk-edit';
import { useGetProjectNodeTypeProperty } from 'api/project-node-type-property/use-get-project-node-type-property';
import { ProjectNodeTypePropertyReturnData } from 'api/types';
import { PropertyTypes } from 'components/form/property/types';
import { ITypePropertyData, ITypePropertyMenu } from 'types/menu-node';

type MenuItem = Required<MenuProps>['items'][number];

export enum FolderType {
  folder,
  all,
}

const isBulkEditDisabled = (data: ProjectNodeTypePropertyReturnData | undefined): boolean => {
  if (!data) return true;
  return (
    data.name === 'name' ||
    data.unique_type === true ||
    data.ref_property_type_id === PropertyTypes.Document ||
    data.display_name === 'default_image' ||
    data.ref_property_type_id === 'image_url'
  );
};

const menuItems = (
  isDefault: boolean,
  canSetDefault: boolean,
  data: ProjectNodeTypePropertyReturnData | undefined
): MenuItem[] =>
  [
    {
      key: 'edit',
      icon: <Edit />,
      label: <MenuText>Edit</MenuText>,
    },
    {
      key: 'bulk_edit',
      icon: <EditBulk />,
      label: <MenuText>Bulk Edit</MenuText>,
      disabled: isBulkEditDisabled(data),
    },
    {
      key: 'delete',
      icon: <Delete />,
      label: <MenuText>Delete</MenuText>,
      disabled: isDefault,
    },
  ] as MenuItem[];

export const TypePropertyMenu = ({ propertyId, isDefault, canSetDefault, closeManageNodes }: ITypePropertyMenu) => {
  const { dispatch } = useTypeProperty();
  const [isEditOpened, setEditOpened] = useState(false);
  const [isBulkEditOpened, setBulkEditOpened] = useState(false);

  const {
    state: { progressStart, progressStop },
  } = useImport();

  const { data } = useGetProjectNodeTypeProperty(propertyId, {
    enabled: !!propertyId,
  });

  const { nodeTypeId } = useDataSheetWrapper();
  const { mutate } = useSetPropertyDefault();
  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'edit') {
      dispatch({ type: TypePropertyActionKind.EDIT_TYPE_START, payload: { propertyId } });
      setEditOpened(true);
    } else if (e.key === 'delete') {
      dispatch({ type: TypePropertyActionKind.DELETE_TYPE_START, payload: { propertyId } });
    } else if (e.key === 'default' && nodeTypeId) {
      mutate({ propertyId, nodeTypeId: nodeTypeId });
    } else if (e.key === 'bulk_edit' && nodeTypeId) {
      setBulkEditOpened(true);
    }
    closeManageNodes();
    e.domEvent.stopPropagation();
  };

  return (
    <>
      <PropertyMenu
        style={{ width: 256 }}
        mode="vertical"
        selectable={false}
        items={menuItems(isDefault, canSetDefault, data)}
        forceSubMenuRender={false}
        onClick={onClick}
      />
      <DeleteTypePropertyModal id={propertyId} />
      <AddNodeTypePopover
        content={<AddTypePropertyForm isEdit hide={() => setEditOpened(false)} propertyId={propertyId} />}
        open={isEditOpened}
        trigger="click"
        align={{ offset: [50, -150] }}
        onOpenChange={(open: boolean) => {
          !open && setEditOpened(false);
          return open;
        }}
      />
      <BulkEditPopover
        content={
          data ? (
            <BulkEditForm
              propertyId={propertyId}
              setBulkEditOpened={setBulkEditOpened}
              data={{
                ...data,
                template_id: propertyId,
                properties: (data?.properties as ITypePropertyData) || [],
              }}
              name={''}
            />
          ) : null
        }
        open={isBulkEditOpened}
        align={{ offset: [-50, -50] }}
        onOpenChange={(open: boolean) => {
          !open && setBulkEditOpened(false);
          return open;
        }}
      />
      <ProgressBar start={!!progressStart} stop={!!progressStop} />
    </>
  );
};
