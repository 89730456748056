import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import client from '../client';
import { CheckPropertyBodyConnection, CheckPropertyResponseAllConnection } from 'api/types';
export const URL_GET_All_CONNECTION = '/edges/all-data/:project_id';

export const useGetAllConnection = (
  queryParams: CheckPropertyResponseAllConnection,
  options?: CheckPropertyBodyConnection
) => {
  const { id } = useParams();

  const urlNodes = URL_GET_All_CONNECTION.replace(':project_id', id || '');

  const queryResult = useQuery({
    queryKey: [urlNodes, queryParams],
    queryFn: async () => {
      const { data } = await client.post(urlNodes, queryParams);
      return data;
    },
    ...options,
  });

  const { data, isSuccess } = queryResult;

  return {
    ...queryResult,
    rowsData: isSuccess ? data?.rows || [] : [],
    count: isSuccess ? data?.count || 0 : 0,
  };
};
