import client from '../client';
import { useMutation, UseQueryOptions } from '@tanstack/react-query';
import { RequestTypes, TGraphData } from 'api/types';

import { errorMessage } from 'helpers/utils';
import { FuzzyMatchResponseData } from './use-create-fuzzy-match';
import { URL_CREATE_FUZZY_MATCH_GRAPH } from './constant';

export type FuzzyMatchRequest = {
  edgeId: string;
  data: FuzzyMatchResponseData[];
};

type Options = UseQueryOptions<TGraphData, Error, TGraphData>;

export const useCreateFuzzyMatchGraph = (options?: Options) => {
  const mutation = useMutation<{ data: TGraphData }, unknown, FuzzyMatchRequest>({
    mutationFn: (values: FuzzyMatchRequest) => {
      return client[RequestTypes.Post](URL_CREATE_FUZZY_MATCH_GRAPH, { ...values });
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data.data);
    },
    onError: errorMessage,
  });
  return mutation;
};
